import React from "react";
import { useState, useEffect, useRef, } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { TextBox, SelectBox } from 'devextreme-react';
import DataGrid, {
    Column,
    Editing,
    Popup,
    Paging,
    Scrolling,
    Form,
    SearchPanel,
    Export,
    Selection,
} from 'devextreme-react/data-grid';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { GETEXPENSES, INSERT_COSTCENTERS, GETCOSTCENTERS } from "../../api/requestMethods";
import { GET_ALL_EXPENSES, INSERT_COSTCENTER_INSERTION, GET_ALL_COSTCENTERS  } from "../../api/api";
export default function CostCenter() {

    const [show, setShow] = useState(false);
    const [lastClickedRow, setLastClickedRow] = useState(null);
    const [costCenterCollection, setCostCenterCollection] = useState({

        //dummyCostCenters:[],
        expenseHeads: "",
        selectedExpenseHead: ""
    })

    const [costCenters, setCostCenters] = useState([]);
    const[CostCenterforAxios, setCostCenterforAxios] = useState([]);

    const queryClient = useQueryClient()



    const fetchExpenses = async () => await GETEXPENSES(GET_ALL_EXPENSES());
    const { data: allExpenses, isError, error, isLoading, isPlaceholderData, } = useQuery({
        queryKey: ["getAllExpenses"],
        queryFn: () => fetchExpenses(),
        // staleTime: 60 * 1000 * 5
    });




    useEffect(() => {

        const EXPTYPES = allExpenses && allExpenses?.data.map((cdata) => ({
            expense_head: cdata.expense_head,
            expense_code: cdata.expense_code
        }))

        if (EXPTYPES !== null) {
            setCostCenterCollection(prev => ({
                ...prev,
                expenseHeads: EXPTYPES
            }));
        }
        else {
            setCostCenterCollection(prev => ({
                ...prev,
                expenseHeads: ""
            }));
        }

    }, [allExpenses])



    // setting initial selctedexpenseHead
    useEffect(() => {
        const FirstExpenseHead = async () => {
            if (costCenterCollection && costCenterCollection.expenseHeads?.length > 0) {
                const DummyObj = {

                    expense_head: "All",
                    expense_code: "ALL_exp123@/exp"
                };

                const isAnyOptionPresent = costCenterCollection && costCenterCollection.expenseHeads.some(
                    (type) => type.expense_head === "All"
                );

                if (!isAnyOptionPresent) {
                    setCostCenterCollection((prev) => ({
                        ...prev,
                        //selectedTypes: DummyObj.objId,
                        //displayExpr: DummyObj.leaveTypeName,
                        expenseHeads: [DummyObj, ...prev.expenseHeads], // Append "Any" option. here ... operator is another word for add/insert. or use this-  ...spread operator creates a copy of whatev is after it, and then appends something after the comma
                        selectedExpenseHead: DummyObj.expense_head,
                    }));
                }
            }
        };
        FirstExpenseHead();
    }, [costCenterCollection.expenseHeads]);



    const handleShow = () => {
        setShow(true);
        setCostCenters([])
    }

    const handleClose = () => {

        //setFormDataFinal(new FormData());
        //setFormDataFinal([])
        setCostCenters([]);
        setCostCenterforAxios([]);
        //setInsertChecker(false);
        setShow(false);

    }

    const handleAddCostCenter = (expenseCode, index) => {
        const uniqueIndex = `${index}-${costCenters.length + 1}`;
        const newCostCenter = {
            expense_code: expenseCode,
            cost_center: '',
            isOpen: true, 
            index: uniqueIndex
        };
        setCostCenters(prev => [...prev, newCostCenter]);
        
    };

    const handleSubtractCostCenter = (expenseCode, index) => {
      
        const updatedArray = [...costCenters];

       
        const lastIndex = updatedArray.map(center => center.expense_code).lastIndexOf(expenseCode);

  
        if (lastIndex !== -1) {
            updatedArray.splice(lastIndex, 1);
        }

        
        setCostCenters(updatedArray);
    };

    const handleCostCenterChange = (index, value) => {
        setCostCenters(prev => {
            const updatedCostCenters = [...prev];
            updatedCostCenters[index] = { ...updatedCostCenters[index], cost_center: value };
            return updatedCostCenters;
        });
    };

    const handleResetCostCenter = () => {

    }



    const handleInsertCostCenters = () => {

        const nonEmptyCostCenters = costCenters && costCenters.filter(item => item.cost_center !== null && item.cost_center !== "");

        console.log(nonEmptyCostCenters,"check cost centers")
        if(nonEmptyCostCenters.length ===0)
        {
            alert("Please enter an cost Center")
            return
        }

       setCostCenterforAxios(nonEmptyCostCenters);
       postmutationCostCenter.mutate(CostCenterforAxios);


    }

    const handleExpenseChange = (value) => {
        setCostCenterCollection(prev => ({
            ...prev,
            selectedExpenseHead: value,
            dummyCostCenters: [],

        }));
    }

    const insertCostCenter = (CostCenterforAxios) => INSERT_COSTCENTERS(INSERT_COSTCENTER_INSERTION(), CostCenterforAxios);
    const postmutationCostCenter = useMutation({
        mutationKey: ['insertCostCenter'],
        mutationFn: () => insertCostCenter(CostCenterforAxios),
        onSuccess: (data) => {
            queryClient.invalidateQueries({
                queryKey: ["getAllCostCenters"],
            });
            alert(data.message, "success", 3000);
            handleClose()

        },
        onError: (error) => {
            console.error("Error inserting data:", error);

            alert("Did not insert alert box")
            handleClose()
        }
    });


    const fetchCostCenters = async () => await GETCOSTCENTERS(GET_ALL_COSTCENTERS());
    const { data: allCostCenters, isError_CC, error_CC, isLoading_CC, isPlaceholderData_CC, } = useQuery({
        queryKey: ["getAllCostCenters"],
        queryFn: () => fetchCostCenters(),
        // staleTime: 60 * 1000 * 5
    });


    console.log(allCostCenters,"allCostCenters")

    const renderSerialNumber = (data) => {
        const pageIndex = data.component.pageIndex();
        const pageSize = data.component.pageSize();
        const rowIndex = data.rowIndex + 1 + pageIndex * pageSize;
        return <span>{rowIndex}</span>;
    }



    return (
        <>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Insert Cost Centers for each Expense Head</Modal.Title>
                </Modal.Header>
                <Modal.Body>



                    <div>
                        <table>
                            <thead>
                                <tr>
                                    <th>Expense Head</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {costCenterCollection.expenseHeads && costCenterCollection.expenseHeads.map((expenseHead, index) => (
                                    <tr key={index}>
                                        <td>{expenseHead.expense_head}</td>
                                        <td>
                                            <button className='employBtn' onClick={() => handleAddCostCenter(expenseHead.expense_code, index)}>+</button>
                                            <button className='employBtn' onClick={() => handleSubtractCostCenter(expenseHead.expense_code, index)}>-</button>
                                            {costCenters && costCenters.map((costCenter, idx) => (
                                                costCenter.expense_code === expenseHead.expense_code &&
                                                <div key={idx}>
                                                    {costCenter.isOpen && (
                                                        <TextBox
                                                            value={costCenter.cost_center}
                                                            onValueChanged={(e) => handleCostCenterChange(idx, e.value)}
                                                            placeholder="Enter Cost Center"
                                                        />
                                                    )}
                                                </div>
                                            ))}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>




                </Modal.Body>
                <Modal.Footer>

                    <Button variant="primary" onClick={handleInsertCostCenters}>
                        Insert
                    </Button>
                </Modal.Footer>
            </Modal>



            <div className="row d-flex mt-3 gap-3 mx-auto">
                
    {/* <div className="col-lg-4 shadow bg-white p-1"> */}
        <div className=" shadow bg-white p-3">
        <button className='employBtn' onClick={handleShow}>Add Cost Center</button>

            <DataGrid
                className={'content-block px-0 empGridWidth'}
                dataSource={allCostCenters?.data || []}
                
                showBorders={true}
                //onRowInserting={(e) => handleRowInsert(e)}
               
                defaultPageSize={7}
                //onRowUpdated={(e) => handleRowUpdate(e)}
                paging={{ pageSize: 7 }}
                rowAlternationEnabled={true}
                selectionFilter={true}

            >
                <Editing
                    mode="row"
                    //allowAdding={true}
                    allowDeleting={true}
                    allowUpdating={true}
                //onRowUpdating={(e) => console.log("Row updating event triggered:", e)}
                />
                <Column caption="Sno" cellRender={renderSerialNumber} width={50} alignment="center" allowEditing={false} />
                <Column dataField="expense_code" caption="Expense Code"  allowEditing={false} />
                <Column dataField="expense_head" caption="Expense Head"  />
                <Column dataField="cost_center"  caption="Cost Center"/>
                <Column dataField="cost_center_id"  caption="Cost Center ID"/>

            </DataGrid>
            {/* {showUpdateButton && <button className='employBtn' onClick={handleFinalUpdate}>Final Update</button>} */}
        </div>
    {/* </div> */}

   </div>










        </>
    )
}