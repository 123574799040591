import React, { useState, useEffect, createContext, useContext, useCallback } from 'react';
import { getUser, signIn as sendSignInRequest } from '../api/auth';
import { useNavigate } from 'react-router-dom';
import {jwtDecode} from 'jwt-decode'

function AuthProvider(props) {
  const navigate = useNavigate();
  const [user, setUser] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async function () {
      const result = await getUser();
      if (result.isOk) {
        setUser(result.data);
      }

      setLoading(false);
    })();
  }, []);

  const signIn = useCallback(async (userId, password, companyName) => {
    const result = await sendSignInRequest(userId, password, companyName);
    return result;
  }, []);

  const signOut = useCallback(() => {
    setUser(undefined);
    navigate("/");
  }, []);


  return (
    <AuthContext.Provider value={{ user, signIn, signOut, loading, setLoading, setUser }} {...props} />
  );
}

const AuthContext = createContext({ loading: false });
const useAuth = () => useContext(AuthContext);

export { AuthProvider, useAuth }



