import React, { useState, useRef, useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";
import Form, {
  Item,
  Label,
  ButtonItem,
  ButtonOptions,
  RequiredRule,
  EmailRule,
} from "devextreme-react/form";
import LoadIndicator from "devextreme-react/load-indicator";
import notify from "devextreme/ui/notify";
import { useAuth } from "../../contexts/auth";
import { jwtDecode } from "jwt-decode";
import "./LoginForm.scss";
import { ERROR, DURATION } from "../../Global/constants/constants.ts";

import HandleErrorToast from "../../Toaster/Failure/Failure";

export default function LoginForm() {
  const navigate = useNavigate();
  const { signIn, setUser, setLoading, loading } = useAuth();
  const formData = useRef({ userId: "", password: "", companyName: "" });

  const onSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      const { userId, password, companyName } = formData.current;
      setLoading(true);

      const result = await signIn(userId, password, companyName);
      if (result.status === 200) {
        console.log("inside of resu");
        setUser(jwtDecode(result.access));
        localStorage.setItem("token", JSON.stringify(result.data));
        setUser(result.data);
        setLoading(false);
        navigate("/dashboard");
      } else {
        HandleErrorToast(ERROR, result.detail, DURATION);
        setUser(undefined);
        setLoading(false);
      }
    },
    [signIn]
  );

  const onCreateAccountClick = useCallback(() => {
    navigate("/create-account");
  }, [navigate]);

  return (
    <form className={"login-form"} onSubmit={onSubmit}>
      <Form formData={formData.current} disabled={loading}>
        <Item
          dataField={"companyName"}
          editorType={"dxTextBox"}
          editorOptions={companyEditorOptions}
        >
          <RequiredRule message="Company Name is required" />
          <Label visible={false} />
        </Item>

        <Item
          dataField={"userId"}
          editorType={"dxTextBox"}
          editorOptions={emailEditorOptions}
        >
          <RequiredRule message="user Id is required" />
          <Label visible={false} />
        </Item>

        <Item
          dataField={"password"}
          editorType={"dxTextBox"}
          editorOptions={passwordEditorOptions}
        >
          <RequiredRule message="Password is required" />
          <Label visible={false} />
        </Item>

        <Item
          dataField={"rememberMe"}
          editorType={"dxCheckBox"}
          editorOptions={rememberMeEditorOptions}
        >
          <Label visible={false} />
        </Item>
        <ButtonItem>
          <ButtonOptions
            width={"100%"}
            type={"default"}
            useSubmitBehavior={true}
          >
            <span className="dx-button-text">
              {loading ? (
                <LoadIndicator width={"24px"} height={"24px"} visible={true} />
              ) : (
                "Sign In"
              )}
            </span>
          </ButtonOptions>
        </ButtonItem>
        <Item>
          <div className={"link"}>
            <Link to={"/reset-password"}>Forgot password?</Link>
          </div>
        </Item>
        <ButtonItem>
          <ButtonOptions
            text={"Create an account"}
            width={"100%"}
            onClick={onCreateAccountClick}
          />
        </ButtonItem>
      </Form>
    </form>
  );
}

const companyEditorOptions = {
  stylingMode: "filled",
  placeholder: "Company Name",
};

const emailEditorOptions = { stylingMode: "filled", placeholder: "User Id" };
const passwordEditorOptions = {
  stylingMode: "filled",
  placeholder: "Password",
  mode: "password",
};
const rememberMeEditorOptions = {
  text: "Remember me",
  elementAttr: { class: "form-text" },
};
