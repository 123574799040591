import defaultUser from '../utils/default-user';
import { LOGIN } from './api';

import { LOGINBASEPATH } from './api';

export async function signIn(userId, password, companyName) {
  try {
    const response = await fetch(LOGINBASEPATH, {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
        "Database-Name": companyName
      }),
      body: JSON.stringify({
        userId,
        companyName,
        password,
      }),
    });
    
    const FinalResponse = await response.json();
    return FinalResponse
  }
  catch {
    return {
      isOk: false,
      message: "Authentication failed"
    };
  }
}

export async function getUser() {
  try {
    // Send request

    return {
      isOk: false,
      data: defaultUser
    };
  }
  catch {
    return {
      isOk: false
    };
  }
}

export async function createAccount(email, password) {
  try {
    // Send request
    console.log(email, password);

    return {
      isOk: true
    };
  }
  catch {
    return {
      isOk: false,
      message: "Failed to create account"
    };
  }
}

export async function changePassword(email, recoveryCode) {
  try {
    // Send request
    console.log(email, recoveryCode);

    return {
      isOk: true
    };
  }
  catch {
    return {
      isOk: false,
      message: "Failed to change password"
    }
  }
}

export async function resetPassword(email) {
  try {
    // Send request
    console.log(email);

    return {
      isOk: true
    };
  }
  catch {
    return {
      isOk: false,
      message: "Failed to reset password"
    };
  }
}
