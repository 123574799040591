// Dashboard
import { BASEPATH } from "../config/config";

export const LOGIN = `${BASEPATH}/api/login`;

export const LOGINBASEPATH = `${BASEPATH}/company/loginsystem/`;

export const VIEW_EMPLOYEES = () => `company/getAllEmployees`;

export const UPDATE_EMPLOYEES = (empId) =>
  `company/updateemp/?emp_id=${empId}`;

export const DELETE_EMPLOYEES = (empId) =>
  `company/deleteemployee/?emp_id=${empId}`;

export const INDIDUAL_EMPLOYEES_UPLOAD = () => `company/createSingleEmployee/`;

export const BULK_EMPLOYEES_UPLOAD = () => `company/bulkemployeeinsert/`;

export const LOGINSYSTEM = () => `company/loginsystem/`;

//export const CHECK_ROLE = () => `/company/checkwhologin/?companyname=durr&username=rahultest`;

export const EMPLOYEE_PROFILE_DETAILS = (emp_id) =>
  `company/getsingleprofile/?emp_id=${emp_id}`;

export const GET_SINGLE_EMPLOYEE = (emp_id) =>
  `company/getsingleemployee/?emp_id=${emp_id}`;

//profile
export const GET_SINGLE_PROFILE = (emp_id) =>
  `company/getsingleprofile/?emp_id=${emp_id}`;

export const UPDATE_SINGLE_PROFILE = (emp_id) =>
  `company/updatesingleprofile/?emp_id=${emp_id}`;

//policy and expenses
export const BULK_EXPENSES_UPLOAD = () => `company/bulkinsertexpenses/`;
export const GET_ALL_EXPENSES = () => `company/getAllExpenses/`;
export const UPDATE_BULK_EXPENSES = () => `company/bulkupdateexpense/`;
export const GET_EMP_LEVELS = () => `company/getAllEmployeeLevels/`;

export const INSERT_POLICY_UPLOAD = () => `company/insertSinglePolicy/`;
export const GET_ALL_POLICYS = () => `company/getAllPolicy/`;
export const BULK_INSERT_POLICY = () => `company/bulkinsertpolicy/`;
export const UPDATE_BULK_POLICYS = () => `company/bulkupdatepolicy/`;

//cost center
export const INSERT_COSTCENTER_INSERTION = () =>
  `company/createbulkcostcenters/`;
export const GET_ALL_COSTCENTERS = () => `company/getAllCostCenters/`;
