import React, { useState, useEffect, useRef, useCallback } from "react";
import DataGrid, {
  Column,
  Editing,
  Popup,
  Paging,
  Scrolling,
  SearchPanel,
  Export,
  Selection,
  ColumnChooser,
  ColumnFixing,
} from "devextreme-react/data-grid";
import "devextreme-react/text-area";
import { IoIosEye } from "react-icons/io";
import { DateBox, SelectBox } from "devextreme-react";
import { makeGetRequest, makePostRequest } from "../../../api/requestMethods";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import "./mainCategory.css";
import { jsPDF } from "jspdf";
import { exportDataGrid } from "devextreme/pdf_exporter";
import { firstLetterCapsStr } from "../../../utils/reUseableFunctions/reUseableFunctions";
import notify from "devextreme/ui/notify";
import { VIEW_EMPLOYEES } from "../../../api/api";
import BRANDLOGO from "../../../expensezLogo.png";
import {
  INDIDUAL_EMPLOYEES_UPLOAD,
  UPDATE_EMPLOYEES,
  BULK_EMPLOYEES_UPLOAD,
} from "../../../api/api";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import "bootstrap/dist/css/bootstrap.min.css";
import { TextBox } from "devextreme-react";
import { ERROR_MESSAGE, EMP_ROLE } from "../constants/constants";
import ExcelFileUploader from "../../../components/excelFileUploader/excelFileUploader";
import FileUploader from "devextreme-react/file-uploader";
import { useAuth } from "../../../contexts/auth";
import { GET_SINGLE_EMPLOYEE } from "../../../api/api";
import { GETONEEMPLOYEE } from "../../../api/requestMethods";
import { getAPIKey } from "../../../api/getApiKey";
const { Country, State, City } = require("country-state-city");

const Employees = ({ props }) => {
  const { user } = useAuth();
  const apiKey = getAPIKey();
  const apiKeyObject = JSON.parse(apiKey);
  let databasename = "";
  if (apiKeyObject) {
    databasename = apiKeyObject?.databasename;
  }
  const fileUploaderRef = useRef(null);
  const [uploadType, setUploadType] = useState("individual");
  const [show, setShow] = useState(false);
  const [emailerror, setEmailerror] = useState("");
  const [check, setCheck] = useState(false);
  const [showremovebutton, setShowRemoveButton] = useState(false);
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const handleClose = () => {
    setShow(false);
    setShowRemoveButton(false);
  };
  const handleShow = () => {
    setFormData({
      emp_id: "",
      username: "",
      password: "",
      emp_firstname: "",
      emp_lastname: "",
      emp_email: "",
      emp_phone: "",
      emp_level: "",
      emp_role: "",
      emp_dept: "",
      emp_dob: "",
      emp_address: "",
      emp_dateofjoining: "",
      emp_reporting_authority: "",
      emp_country: "",
      emp_city: "",
      emp_state: "",
      emp_zipcode: "",
      emp_image: "",
      isAdmin: "",
      isManager: "",
      isEmployee: "",
      emp_position: "",
    });
    setErrors({});
    setShow(true);
  };
  const [errors, setErrors] = useState({});
  const [excelError, setExcelError] = useState();
  const [excelFile, setExcelFile] = useState({
    EXCELFILE: "",
    TRIGGER: "",
  });
  const [formData, setFormData] = useState({
    emp_id: "",
    username: "",
    password: "",
    company_name: "",
    emp_firstname: "",
    emp_lastname: "",
    emp_email: "",
    emp_phone: "",
    emp_level: "",
    emp_role: "",
    emp_dept: "",
    emp_dob: "",
    emp_address: "",
    emp_dateofjoining: "",
    emp_reporting_authority: "",
    emp_country: "",
    emp_city: "",
    emp_state: "",
    emp_zipcode: "",
    isAdmin: "",
    isManager: "",
    isEmployee: "",
    emp_image: "",
    emp_position: "",
  });

  useEffect(() => {
    if (user) {
      setFormData((prev) => ({
        ...prev,
        company_name: user.databasename,
      }));
    }
  }, []);

  const handleInputChange = async (name, value) => {
    if (name === "emp_role") {
      setFormData((prev) => ({ ...prev, [name]: value }));
      if (value === "Employee") {
        setFormData((prev) => ({
          ...prev,
          isEmployee: true,
          isManager: false,
          isAdmin: false,
        }));
      }
      if (value === "Manager") {
        setFormData((prev) => ({
          ...prev,
          isEmployee: false,
          isManager: true,
          isAdmin: false,
        }));
      }
      if (value === "Admin") {
        setFormData((prev) => ({
          ...prev,
          isEmployee: false,
          isManager: false,
          isAdmin: true,
        }));
      }
    } else if (name === "emp_email") {
      if (!emailRegex.test(value)) {
        setEmailerror("Please enter a valid email");
      } else {
        setFormData((prev) => ({ ...prev, [name]: value }));
        setEmailerror(null);
      }
    } else {
      setFormData((prev) => ({ ...prev, [name]: value }));
    }
    setErrors((prev) => ({ ...prev, [name]: "" }));
  };

  const handleUploadTypeChange = (e) => {
    setUploadType(e.target.value);
  };

  const EventHandler = (event) => {
    event?.preventDefault();
    const excludedFields = [];
    const newErrors = Object.keys(formData).reduce((errors, fieldName) => {
      if (excludedFields.includes(fieldName)) {
        return errors;
      }
      const fieldValue = formData[fieldName];
      if (typeof fieldValue === "string" && fieldValue.trim() === "") {
        errors[fieldName] = ERROR_MESSAGE;
      }
      return errors;
    }, {});

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
      let formDataForApi = new FormData();
      formDataForApi.append("company_name", user.databasename);
      Object.entries(formData).forEach(([key, value]) => {
        if (key === "emp_image") {
          formDataForApi.append(key, value, value.name);
        } else if (key === "emp_country") {
          const selectedCountry =
            countryOptions &&
            countryOptions.find((country) => country.value === value);
          if (selectedCountry) {
            formDataForApi.append(key, selectedCountry.label);
          } else {
            formDataForApi.append(key, value);
          }
        } else if (key === "emp_state") {
          const selectedState =
            stateOptions && stateOptions.find((state) => state.value === value);
          if (selectedState) {
            formDataForApi.append(key, selectedState.label);
          } else {
            formDataForApi.append(key, value);
          }
        } else if (key === "emp_city") {
          const selectedCity =
            cityOptions && cityOptions.find((city) => city.value === value);
          if (selectedCity) {
            formDataForApi.append(key, selectedCity.label);
          } else {
            formDataForApi.append(key, value);
          }
        } else {
          formDataForApi.append(key, value);
        }
      });
      mutation.mutate(formDataForApi);
      setFormData({
        emp_id: "",
        username: "",
        password: "",
        emp_firstname: "",
        emp_lastname: "",
        emp_email: "",
        emp_phone: "",
        emp_level: "",
        emp_role: "",
        emp_dept: "",
        emp_dob: "",
        emp_address: "",
        emp_dateofjoining: "",
        emp_reporting_authority: "",
        emp_country: "",
        emp_city: "",
        emp_state: "",
        emp_zipcode: "",
        emp_image: "",
        isAdmin: "",
        isManager: "",
        isEmployee: "",
        emp_position: "",
      });
      setErrors({});
    }
  };

  const handleFileUploaded = (file) => {
    const formData = new FormData();
    formData.append("employee", file);
    setExcelFile(formData);
  };

  const queryClient = useQueryClient();
  const [menu, setMenu] = useState({
    CATEGORY: "",
    ADD_CATEGORY_OR_UPDATE: "",
    UPDATE_CATEGORY: "",
  });

  const renderSerialNumber = (data) => {
    const pageIndex = data.component.pageIndex();
    const pageSize = data.component.pageSize();
    const rowIndex = data.rowIndex + 1 + pageIndex * pageSize;
    return <span>{rowIndex}</span>;
  };

  const addOrUpdate = (flag) => {
    setMenu((prev) => ({
      ...prev,
      ADD_CATEGORY_OR_UPDATE: `${flag} ${firstLetterCapsStr(
        formData.category
      )}`,
    }));
  };

  const exportFormats = ["pdf"];
  const onExporting = (e) => {
    const exportFormat = e?.format?.toLowerCase();

    if (exportFormat === "pdf") {
      const doc = new jsPDF("l", "mm", "a4"); // Landscape mode for more space

      // Set up smaller logo dimensions
      const imgWidth = 20; // Reduced width
      const imgHeight = 20; // Reduced height
      const imgX = 10; // Margin for the logo (left)
      const imgY = 10; // Margin for the logo (top)

      const contentStartY = imgY + imgHeight + 10; // Space below the logo

      // Add logo in the top-left corner
      doc.addImage(BRANDLOGO, "PNG", imgX, imgY, imgWidth, imgHeight);

      // Add heading
      const headingText = "Expens-EZ";
      const headingFontSize = 12;
      doc.setFontSize(headingFontSize);
      doc.text(
        headingText,
        doc.internal.pageSize.width / 2,
        imgY + imgHeight / 2,
        "center"
      );

      // Add current date
      const currentDate = new Date().toLocaleString();
      doc.setFontSize(10);
      const dateX = doc.internal.pageSize.width - 10; // Right margin
      const dateY = imgY + imgHeight / 2;
      doc.text(currentDate, dateX, dateY, "right");

      // Export only the first 8 columns
      exportDataGrid({
        jsPDFDocument: doc,
        component: e.component,
        topLeft: { x: 10, y: contentStartY },
        indent: 5,
        customizeCell: (options) => {
          // Reduce font size for table cells to fit more content
          options.pdfCell.fontSize = 8;
        },
        customizeExportColumns: (columns) => {
          // Select only the first 8 columns
          return columns.slice(0, 8);
        },
      }).then(() => {
        // Map and transform rows if necessary
        const rows = e.component.getVisibleRows();
        rows.forEach((row) => {
          if (row.data.status === "Y") {
            row.data.status = "Active";
          } else if (row.data.status === "N") {
            row.data.status = "Inactive";
          }
        });
        doc.save("Employees.pdf");
      });
    }
  };

  const fetchQuery = async () => await makeGetRequest(VIEW_EMPLOYEES());
  const {
    data: allEmployees,
    error,
  } = useQuery({
    queryKey: ["getAllEmployees"],
    queryFn: () => fetchQuery(),
  });

  const insertData = (newData) =>
    makePostRequest(
      excelFile.TRIGGER !== "excel"
        ? INDIDUAL_EMPLOYEES_UPLOAD()
        : BULK_EMPLOYEES_UPLOAD(),
      newData
    );
  const mutation = useMutation({
    mutationKey: ["insertMainCategory"],
    mutationFn: insertData,
    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: ["getAllEmployees"],
      });
      handleClose();
    },
    onError: (error) => {
      console.error("Error inserting data:", error);
      handleClose();
    },
  });
  const mutationKeyUpdate = ["updateMainCategory"];
  const updateData = (updatedData) =>
    makePostRequest(UPDATE_EMPLOYEES(), updatedData);
  const mutationUpdate = useMutation({
    mutationKey: mutationKeyUpdate,
    mutationFn: updateData,
    onSuccess: (data) => {
      notify(data.message, "success", 3000);
    },
    onError: (error) => {
      console.error("Error updating data:", error);
    },
  });

  const handleRowUpdate = (e) => {
    const data = e.data;
    const body = {
      type: menu.CATEGORY,
      main_cate_id: data.main_cat_id,
      main_cate_name: data.main_cat_name,
      status: data.status,
    };
    mutationUpdate.mutate(body);
  };

  const ExcelFileUploaderEvent = () => {
    if (!excelFile) {
      setExcelError("Please upload an Excel file.");
      return;
    }
    setExcelFile((prev) => ({ ...prev, TRIGGER: "excel" }));
    mutation.mutate(excelFile);
  };

  const handleFileSelected = (e) => {
    const file = e?.value[0];
    if (file) {
      setFormData((prevState) => ({ ...prevState, emp_image: file }));
      setShowRemoveButton(true);
    }
  };
  useEffect(() => {
    if (user && user.databasename) {
      setFormData((prev) => ({
        ...prev,
        company_name: user.databasename,
      }));
    }
  }, [user]);

  const handleFileRemove = () => {
    if (fileUploaderRef.current) {
      fileUploaderRef.current.instance.reset();
      setShowRemoveButton(false);
    }
  };

  const [countryOptions, setCountryOptions] = useState([]);
  const [stateOptions, setStateOptions] = useState([]);
  const [cityOptions, setCityOptions] = useState([]);

  useEffect(() => {
    const countries = Country.getAllCountries().map((country) => ({
      label: country.name,
      value: country.isoCode,
    }));
    setCountryOptions(countries);
  }, []);

  useEffect(() => {
    if (formData.emp_country) {
      const states = State.getStatesOfCountry(formData.emp_country).map(
        (state) => ({
          label: state.name,
          value: state.isoCode,
        })
      );
      setStateOptions(states);
    } else {
      setStateOptions([]);
    }
  }, [formData.emp_country]);

  useEffect(() => {
    if (formData.emp_state) {
      const cities = City.getCitiesOfState(
        formData.emp_country,
        formData.emp_state
      ).map((city) => ({
        label: city.name,
        value: city.name,
      }));
      setCityOptions(cities);
    } else {
      setCityOptions([]);
    }
  }, [formData.emp_state]);

  const actionButtonTemplate = (cellData) => {
    const buttonStyle = {
      backgroundColor: "#FFA500",
    };
    return (
      <IoIosEye
        className="ticketViewBut"
        stylingMode="contained"
        elementAttr={{ style: buttonStyle }}
        onClick={() => HandleActionButtonClick(cellData.data)}
      />
    );
  };
  const HandleActionButtonClick = (rowData) => {
    try {
      setCheck(true);
      mutationget.mutate(rowData.emp_id);
    } catch (error) {
      console.error("Error fetching detailed data :", error);
    }
  };

  const fetchSingleEmployee = (emp_id) =>
    GETONEEMPLOYEE(GET_SINGLE_EMPLOYEE(emp_id));

  const mutationget = useMutation({
    mutationKey: ["fetchSingleEmployee"],
    mutationFn: (emp_id) => check && fetchSingleEmployee(emp_id),
    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: ["fetchSingleEmployee"],
      });
    },
    onError: (error) => {
      console.error("Error fetching single employee:", error);
    },
  });

  return (
    <React.Fragment>
        <div className={'dx-card catWidth'}>
          <div id="data-grid-demo">
            <div className="empAddBtnPos">
              <button className="employBtn" onClick={handleShow}>
                Add Employees
              </button>
            </div>
            <DataGrid
              className={"content-block empGridWidth"}
              dataSource={allEmployees?.data || []}
              showBorders={true}
              onRowUpdated={(e) => handleRowUpdate(e)}
              defaultPageSize={7}
              paging={{ pageSize: 7 }}
              rowAlternationEnabled={true}
              selectionFilter={true}
              onInitNewRow={(e) => addOrUpdate("Create")}
              onEditingStart={(e) => addOrUpdate("Update")}
              onExporting={onExporting}
            >
              <Selection mode="multiple" />
              <ColumnChooser enabled={true} />
              <SearchPanel visible={true} highlightCaseSensitive={true} />

              <Editing mode="popup" allowDeleting={true} allowUpdating={true}>
                <Popup
                  title={`${firstLetterCapsStr(
                    menu.ADD_CATEGORY_OR_UPDATE
                  )} Employees`}
                  showTitle={true}
                  width={700}
                  height={525}
                />
              </Editing>

              <Export
                enabled={true}
                formats={exportFormats}
                allowExportSelectedData={true}
              />
              <Scrolling mode="standard"></Scrolling>
              <Paging
                enabled={true}
                defaultPageSize={15}
                Scrolling
                mode="standard"
              />

              <Column
                caption="#"
                cellRender={renderSerialNumber}
                width={100}
                visible={false}
                alignment="center"
              />
              <Column
                dataField="emp_id"
                caption="Emp Id"
                width={120}
                allowEditing={true}
              />
              <Column dataField="username" caption="Username" width={100} />
              <Column
                dataField="emp_firstname"
                caption="First Name"
                width={150}
              />
              <Column
                dataField="emp_lastname"
                caption="Last Name"
                width={150}
              />
              <Column dataField="emp_email" caption="Email" width={150} />
              <Column
                dataField="emp_phone"
                caption="Phone"
                width={150}
                allowEditing={true}
              />
              <Column dataField="emp_dept" caption="Department" width={150} />
              <Column
                dataField="emp_level"
                caption="Employee Level"
                width={150}
              />
              <Column
                dataField="emp_role"
                caption="Employee Role"
                width={150}
              />
              <Column
                dataField="emp_address"
                caption="Address"
                width={150}
                allowEditing={true}
              />

              <Column
                dataField="emp_dateofjoining"
                caption="Date of Joining"
                dataType="date"
                width={150}
                visible={false}
                allowEditing={true}
              />

              <Column
                dataField="emp_dob"
                caption="Date of Birth"
                dataType="date"
                width={150}
                allowEditing={true}
                visible={false}
              />

              <Column
                dataField="emp_reporting_authority"
                caption="Reporting Authority"
                width={150}
                visible={false}
              />

              <Column
                dataField="isAdmin"
                caption="isAdmin"
                width={150}
                visible={false}
              />
              <Column
                dataField="isManager"
                caption="isManager"
                width={150}
                visible={false}
              />
              <Column
                dataField="isEmployee"
                caption="isEmployee"
                width={150}
                visible={false}
              />
              <Column
                dataField="emp_position"
                caption="Employee Position"
                width={150}
              />
            </DataGrid>
          </div>
        </div>
      <Modal size="lg" aria-labelledby="contained-modal-title-vcenter" centered show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add Employees</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <label className="empPopupHeadBtn">
              <input
                type="radio"
                name="uploadType"
                className="me-2"
                value="individual"
                checked={uploadType === "individual"}
                onChange={handleUploadTypeChange}
              />
              Individual Upload
            </label>
            <label className="empPopupHeadBtn ms-3">
              <input
                type="radio"
                className="me-2"
                name="uploadType"
                value="bulk"
                checked={uploadType === "bulk"}
                onChange={handleUploadTypeChange}
              />
              Bulk Upload
            </label>
          </div>

          {uploadType === "individual" ? (
            <div>
              <div className="row d-flex align-items-baseline mt-0">
                <div className="col-lg-6 pt-3">
                  <TextBox
                    label="Employee Id:"
                    labelMode="floating"
                    onValueChanged={(e) => handleInputChange("emp_id", e.value)}
                    value={formData.emp_id}
                  ></TextBox>

                  {errors.emp_id && (
                    <div className="error-message">{errors.emp_id}</div>
                  )}
                </div>

                <div className="col-lg-6 pt-3">
                  <TextBox
                    label="Username:"
                    labelMode="floating"
                    onValueChanged={(e) =>
                      handleInputChange("username", e.value)
                    }
                    value={formData.username}
                  ></TextBox>
                  {errors.username && (
                    <div className="error-message">{errors.username}</div>
                  )}
                </div>
                <div className="col-lg-6 pt-3">
                  <TextBox
                    label="Password:"
                    labelMode="floating"
                    onValueChanged={(e) =>
                      handleInputChange("password", e.value)
                    }
                    value={formData.password}
                  ></TextBox>
                  {errors.password && (
                    <div className="error-message">{errors.password}</div>
                  )}
                </div>

                <div className="col-lg-6 pt-3">
                  <TextBox
                    label="Company Name"
                    //labelMode="floating"
                    value={user.databasename}
                    readOnly={true}
                  ></TextBox>
                  {/* {errors.company_name && (
                    <div className="error-message">{errors.company_name}</div>
                  )} */}
                </div>

                <div className="col-lg-6 pt-3">
                  <TextBox
                    label="Employee Firstname:"
                    labelMode="floating"
                    onValueChanged={(e) =>
                      handleInputChange("emp_firstname", e.value)
                    }
                    value={formData.emp_firstname}
                  ></TextBox>
                  {errors.emp_firstname && (
                    <div className="error-message">{errors.emp_firstname}</div>
                  )}
                </div>
                <div className="col-lg-6 pt-3">
                  <TextBox
                    label="Employee Lastname:"
                    labelMode="floating"
                    onValueChanged={(e) =>
                      handleInputChange("emp_lastname", e.value)
                    }
                    value={formData.emp_lastname}
                  ></TextBox>
                  {errors.emp_lastname && (
                    <div className="error-message">{errors.emp_lastname}</div>
                  )}
                </div>

                <div className="col-lg-6 pt-3">
                  <TextBox
                    label="Employee Email:"
                    labelMode="floating"
                    onValueChanged={(e) =>
                      handleInputChange("emp_email", e.value)
                    }
                    value={formData.emp_email}
                  ></TextBox>
                  {emailerror && (
                    <div className="error-message">{emailerror} </div>
                  )}
                  {errors.emp_email && (
                    <div className="error-message">{errors.emp_email}</div>
                  )}
                </div>

                <div className="col-lg-6 pt-3">
                  <TextBox
                    label="Employee Phone:"
                    labelMode="floating"
                    onValueChanged={(e) =>
                      handleInputChange("emp_phone", e.value)
                    }
                    value={formData.emp_phone}
                  ></TextBox>
                  {errors.emp_phone && (
                    <div className="error-message">{errors.emp_phone}</div>
                  )}
                </div>
                <div className="col-lg-6 pt-3">
                  <TextBox
                    label="Employee Level:"
                    labelMode="floating"
                    onValueChanged={(e) =>
                      handleInputChange("emp_level", e.value)
                    }
                    value={formData.emp_level}
                  ></TextBox>
                  {errors.emp_level && (
                    <div className="error-message">{errors.emp_level}</div>
                  )}
                </div>

                {/* <div className="col-lg-6 pt-3">
                  <SelectBox
                    label="Employee Level:"
                    labelMode="floating"
                    dataSource={EMP_LEVEL}
                    displayExpr="name"
                    valueExpr="name"
                    onValueChanged={(e) => handleInputChange('emp_level', e.value)}
                    value={formData.emp_level}
                  ></SelectBox>
                  {errors.emp_level && (
                    <div className="error-message">{errors.emp_level}</div>
                  )}

                </div> */}
                <div className="col-lg-6 pt-3">
                  <SelectBox
                    label="Employee Role:"
                    labelMode="floating"
                    dataSource={EMP_ROLE}
                    displayExpr="name"
                    valueExpr="name"
                    onValueChanged={(e) =>
                      handleInputChange("emp_role", e.value)
                    }
                    value={formData.emp_role}
                  ></SelectBox>
                  {errors.emp_role && (
                    <div className="error-message">{errors.emp_role}</div>
                  )}
                </div>
                <div className="col-lg-6 pt-3">
                  <TextBox
                    label="Employee Department:"
                    labelMode="floating"
                    onValueChanged={(e) =>
                      handleInputChange("emp_dept", e.value)
                    }
                    value={formData.emp_dept}
                  ></TextBox>
                  {errors.emp_dept && (
                    <div className="error-message">{errors.emp_dept}</div>
                  )}
                </div>
                {/* <div className="col-lg-6 pt-3">
                  <SelectBox
                    label="Employee Dept:"
                    labelMode="floating"
                    dataSource={EMP_DEPARTMENT}
                    onValueChanged={(e) => handleInputChange('emp_dept', e.value)}
                    displayExpr="name"
                    valueExpr="name"
                    value={formData.emp_dept}
                  ></SelectBox>
                  {errors.emp_dept && (
                    <div className="error-message">{errors.emp_dept}</div>
                  )}

                </div> */}
                <div className="col-lg-6 pt-3">
                  <DateBox
                    type="date"
                    value={formData.emp_dob}
                    placeholder="Select Date of Birth"
                    calendarOptions={{
                      // maxZoomLevel: "year",
                      // minZoomLevel: "decade",
                      displayFormat: "yyyy-MM-dd",
                    }}
                    onValueChanged={(e) => {
                      if (e.value) {
                        const date = new Date(e.value);
                        const year = date.getFullYear();
                        const month = String(date.getMonth() + 1).padStart(
                          2,
                          "0"
                        );
                        const day = String(date.getDate()).padStart(2, "0");
                        const formattedDate = `${year}-${month}-${day}`;

                        setFormData((prev) => ({
                          ...prev,
                          emp_dob: formattedDate,
                        }));
                        setErrors((prev) => ({
                          ...prev,
                          emp_dob: "",
                        }));
                      } else {
                        setFormData((prev) => ({
                          ...prev,
                          emp_dob: null,
                        }));
                      }
                    }}
                  ></DateBox>
                  {errors.emp_dob && (
                    <div className="error-message">{errors.emp_dob}</div>
                  )}
                </div>
                <div className="col-lg-6 pt-3">
                  <TextBox
                    label="Employee Address:"
                    labelMode="floating"
                    onValueChanged={(e) =>
                      handleInputChange("emp_address", e.value)
                    }
                    value={formData.emp_address}
                  ></TextBox>
                  {errors.emp_address && (
                    <div className="error-message">{errors.emp_address}</div>
                  )}
                </div>

                {/* <div className="col-lg-6 pt-3">
                <input type="file" name="emp_image" onChange={handleFileChange} accept="image/*" />
                </div> */}

                <div className="col-lg-6 pt-3">
                  <FileUploader
                    selectButtonText="Select photo"
                    ref={fileUploaderRef}
                    labelText=""
                    accept="image/*"
                    uploadMode="useForm"
                    onValueChanged={handleFileSelected}
                  />
                  {showremovebutton && (
                    <Button onClick={handleFileRemove}>
                      Remove this Picture
                    </Button>
                  )}
                </div>

                <div className="col-lg-6 pt-3">
                  <DateBox
                    type="date"
                    placeholder="Select Date of joining"
                    calendarOptions={{
                      // maxZoomLevel: "year",
                      // minZoomLevel: "decade",
                      displayFormat: "yyyy-MM-dd",
                    }}
                    value={formData.emp_dateofjoining}
                    onValueChanged={(e) => {
                      if (e.value) {
                        const date = new Date(e.value);
                        const year = date.getFullYear();
                        const month = String(date.getMonth() + 1).padStart(
                          2,
                          "0"
                        );
                        const day = String(date.getDate()).padStart(2, "0");
                        const formattedDate = `${year}-${month}-${day}`;

                        setFormData((prev) => ({
                          ...prev,
                          emp_dateofjoining: formattedDate,
                        }));

                        setErrors((prev) => ({
                          ...prev,
                          emp_dateofjoining: "",
                        }));
                      } else {
                        setFormData((prev) => ({
                          ...prev,
                          emp_dateofjoining: null,
                        }));
                      }
                    }}
                  ></DateBox>

                  {errors.emp_dateofjoining && (
                    <div className="error-message">
                      {errors.emp_dateofjoining}
                    </div>
                  )}
                </div>
                <div className="col-lg-6 pt-3">
                  <TextBox
                    label="Reporting Manager:"
                    labelMode="floating"
                    onValueChanged={(e) =>
                      handleInputChange("emp_reporting_authority", e.value)
                    }
                    value={formData.emp_reporting_authority}
                  ></TextBox>
                  {errors.emp_reporting_authority && (
                    <div className="error-message">
                      {errors.emp_reporting_authority}
                    </div>
                  )}
                </div>

                <div className="col-lg-6 pt-3">
                  <TextBox
                    label="Employee Position:"
                    labelMode="floating"
                    onValueChanged={(e) =>
                      handleInputChange("emp_position", e.value)
                    }
                    value={formData.emp_position}
                  ></TextBox>
                  {errors.emp_position && (
                    <div className="error-message">{errors.emp_position}</div>
                  )}
                </div>

                <div className="col-lg-6 pt-3">
                  <SelectBox
                    //id="emp_country"
                    dataSource={countryOptions}
                    displayExpr="label"
                    valueExpr="value"
                    value={formData.emp_country}
                    onValueChanged={(e) =>
                      handleInputChange("emp_country", e.value)
                    }
                    placeholder="Select Country"
                  />
                </div>
                <div className="col-lg-6 pt-3">
                  <SelectBox
                    //id="emp_state"
                    dataSource={stateOptions}
                    displayExpr="label"
                    valueExpr="value"
                    value={formData.emp_state}
                    onValueChanged={(e) =>
                      handleInputChange("emp_state", e.value)
                    }
                    placeholder="Select State"
                  />
                </div>
                <div className="col-lg-6 pt-3">
                  <SelectBox
                    //id="emp_city"
                    dataSource={cityOptions}
                    displayExpr="label"
                    valueExpr="label"
                    value={formData.emp_city}
                    onValueChanged={(e) =>
                      handleInputChange("emp_city", e.value)
                    }
                    placeholder="Select City"
                  />
                </div>

                <div className="col-lg-6 pt-3">
                  <TextBox
                    label="Employee Zip Code:"
                    labelMode="floating"
                    onValueChanged={(e) =>
                      handleInputChange("emp_zipcode", e.value)
                    }
                    value={formData.emp_zipcode}
                  ></TextBox>
                  {errors.emp_zipcode && (
                    <div className="error-message">{errors.emp_zipcode}</div>
                  )}
                </div>
              </div>
            </div>
          ) : (
            <div>
              <ExcelFileUploader onFileUploaded={handleFileUploaded} />
              {error && <p style={{ color: "red" }}>{error}</p>}
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          {uploadType === "individual" ? (
            <Button className="employBtn" onClick={EventHandler}>
              Submit
            </Button>
          ) : (
            <Button className="employBtn" onClick={ExcelFileUploaderEvent}>
              Submit
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
};

export default Employees;
