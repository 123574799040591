export const EMP_LEVEL = [
    {
        id: 1,
        name: 'Manager',
        value: 'Manager'
    },
    {
        id: 2,
        name: 'Grade 1',
        value: 'Grade 1'
    },
    {
        id: 3,
        name: 'Grade 2',
        value: 'Grade 2'
    },
    {
        id: 4,
        name: 'Tech Lead',
        value: 'Tech Lead'
    },
    {
        id: 5,
        name: 'Junior Engineer',
        value: 'Junior Engineer'
    },
]

export const EMP_ROLE=[
    {
         id:1,
         name:'Admin',
         value:'Admin'
    },
    {
        id:2,
        name:'Manager',
        value:'Manager'
    },
    {
         id:3,
         name:'Employee',
         value:'Employee'
    }
]

export const EMP_DEPARTMENT = [
    {
        id: 1,
        name: 'IT',
        value: 'IT',
    },
    {
        id: 2,
        name: 'Electrical',
        value: 'Electrical',
    },
    {
        id: 3,
        name: 'Mechanical',
        value: 'Mechanical',
    },
    {
        id: 4,
        name: 'Aerospace',
        value: 'Aerospace',
    },

]

export const ERROR_MESSAGE = 'This Field is Required'

