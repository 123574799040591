export const YEARS_COLLECTION=[
    {
         id:1,
         name:'2024',
         
    },
    {
        id:2,
        name:'2023',
       
    },
    {
         id:4,
         name:'2022',
        
    },
    {
        id:5,
        name:'2021',
       
   },
   {
    id:6,
    name:'2020',
   
},
{
    id:7,
    name:'2019',
   
},
]

export const MONTHS_COLLECTION=[
    {
        id:1,
        name:'January',
        
   },
   {
       id:2,
       name:'February',
      
   },
   {
        id:3,
        name:'March',
       
   },
   {
       id:4,
       name:'April',
      
  },
  {
   id:5,
   name:'May',
  
},
{
   id:6,
   name:'June',
  
},
{
    id:7,
    name:'July',
   
 },
 {
    id:8,
    name:'August',
   
 },
 {
    id:9,
    name:'September',
   
 },
 {
    id:10,
    name:'October',
   
 },
 {
    id:11,
    name:'November',
   
 },
 {
    id:12,
    name:'Decemeber',
   
 },

]

export const dataSource = [
    {
      company: 'January',
      yClaimed: 377.28,
      yApproved: 270.25,
    },
    {
      company: 'February',
      yClaimed: 353.49,
      yApproved: 311.43,
    },
    {
      company: 'March',
      yClaimed: 269.86,
      yApproved: 273.32,
    },
    {
      company: 'April',
      yClaimed: 252.95,
      yApproved: 280.5,
    },
    {
      company: 'May',
      yClaimed: 197.67,
      yApproved: 165.89,
    },
    {
        company: 'June',
        yClaimed: 165.67,
        yApproved: 165.89,
      },
   
      {
        company: 'July',
        yClaimed: 197.67,
        yApproved: 143.89,
      },
   
      {
        company: 'August',
        yClaimed: 197.67,
        yApproved: 165.89,
      },
     
      {
        company: 'September',
        yClaimed: 196.67,
        yApproved: 200.89,
      },
      {
        company: 'October',
        yClaimed: 197.67,
        yApproved: 326.89,
      },
   
      {
        company: 'November',
        yClaimed: 197.67,
        yApproved: 111.89,
      },
   
      {
        company: 'December',
        yClaimed: 197.67,
        yApproved: 189.89,
      },
   
  ];

  export const populationByRegions = [
    {
      region: 'Telephone',
      val: 4119,
    },
    {
      region: 'Books and Periodicals',
      val: 1012,
    },
    {
      region: 'Car Repairs and Maintenance',
      val: 3441,
    },
    {
      region: 'Beverages',
      val: 5909,
    },
    {
      region: 'Travel',
      val: 7270,
    },
   
  ];

  export const grossProductData = [
    {
      state: 'Telephone',
      year2024: 803,
      year2023: 823,
      year2022: 863,
      year2021: 770,
      year2020: 799,
      year2019: 645,
    },
    {
      state: 'Books and Periodicals',
      year2024: 645,
      year2023: 623,
      year2022: 666,
      year2021: 631,
      year2020: 686,
      year2019: 691,
    },
    {
      state: 'Travel',
      year2024: 455,
      year2023: 468,
      year2022: 432,
      year2021: 486,
      year2020: 411,
      year2019: 477,
    },
    {
      state: 'Beverages',
      year2024: 300,
      year2023: 700,
      year2022: 123,
      year2021: 567,
      year2020: 686,
      year2019: 455,
    },
   
  ];