import React from "react";
import { useState, useEffect, useRef,useMemo } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { GET_ALL_POLICYS, GET_ALL_EXPENSES, UPDATE_BULK_POLICYS } from "../../../api/api";
import { GETPOLICYS, GETEXPENSES, UPDATEBULKPOLICYS } from "../../../api/requestMethods";
import {
    Chart,
    Series,
    CommonSeriesSettings,
    Legend,
    ValueAxis,
    Title,
    //Export,
    Tooltip,
} from 'devextreme-react/chart';
import DataGrid, {
    Column,
    Editing,
    Popup,
    Paging,
    Scrolling,
    Form,
    SearchPanel,
    Export,
    Selection,
} from 'devextreme-react/data-grid';
import {
    CircularGauge,
    Scale,
    Label,
    RangeContainer,
    Range,
    //Title,
    Font,
    //Export,
} from 'devextreme-react/circular-gauge';
import { DateBox, SelectBox } from 'devextreme-react';

export default function History() {

    const [policyHistory, setPolicyHistory] = useState({
        expenseHeadHorde: "",
        emplevelHorde: "",
        sltdExpense_head: "",
        sltdEmp_level: "",
        gridData: "",

        sumeligible: "",
        GrandTotal: ""
    })
    const queryClient = useQueryClient()
    const [showUpdateButton, setShowUpdateButton] = useState(false)
    const [transformedPolicyData, setTransformedPolicyData] = useState([]);
    const [formDataforUpdate, setFormDataForUpdate] = useState([]);
    const [barchartarray, setbarchartarray] = useState([])
    const [counter, setCounter] = useState(0);
    //tanstack fetch for policy
    const fetchPolicys = async () => await GETPOLICYS(GET_ALL_POLICYS());
    const { data: allPolicys, isError3, error3, isLoading3, isPlaceholderData3, } = useQuery({
        queryKey: ["getAllPolicys"],
        queryFn: () => fetchPolicys(),
        // staleTime: 60 * 1000 * 5
    });



    //tanstack fetch for Expenses

    const fetchExpenses = async () => await GETEXPENSES(GET_ALL_EXPENSES());
    const { data: allExpenses, isError, error, isLoading, isPlaceholderData, } = useQuery({
        queryKey: ["getAllExpenses"],
        queryFn: () => fetchExpenses(),
        // staleTime: 60 * 1000 * 5
    });






    //

    useEffect(() => {
        const transformedData = allPolicys && allPolicys.data.reduce((acc, policy) => {
            //console.log(policy, "policy inside TRANSFORM")
            if (policy.expense_head === 'All' && allExpenses) {

                let transformedPolicies = allExpenses && allExpenses?.data?.map(expense => ({
                    ...policy,
                    expense_head: expense.expense_head,
                    expense_code: expense.expense_code
                }));

                //console.log(transformedPolicies, "tRANpOLICIES INSIDE TRANSFORM")
                //console.log(acc, "ACCUMULATOR inside All")
                return [...acc, ...transformedPolicies];

                //here transformedPolicies is elements of array which is appended , in "Any" , DummyObject is being appended

            }
            else {
                //console.log(acc, "ACCUMULATOR inside else")
                return [...acc, policy];
            }
        }, []);

        setTransformedPolicyData(transformedData);
    }, [allPolicys, allExpenses, counter]);

    //console.log(transformedPolicyData, "transformedData FINAL")


    useEffect(() => {
        const uniqueExpHeads = [];
        const POLICYEXPENSEHEADS = transformedPolicyData && transformedPolicyData?.reduce((accumulator, currentItem) => {
            if (!uniqueExpHeads.includes(currentItem.expense_head)) {
                uniqueExpHeads.push(currentItem.expense_head);
                accumulator.push({ exp_heads: currentItem.expense_head });
            }
            return accumulator;
        }, []);

        const uniqueEmpGrades = []
        const EMPEMPLOYGRADES = transformedPolicyData && transformedPolicyData?.reduce((accumulator, currentItem) => {
            if (!uniqueEmpGrades.includes(currentItem.employee_level)) {
                uniqueEmpGrades.push(currentItem.employee_level);
                accumulator.push({ emp_levels: currentItem.employee_level });
            }
            return accumulator;
        }, []);



        if (POLICYEXPENSEHEADS !== null) {
            setPolicyHistory((prev) => ({
                ...prev,
                expenseHeadHorde: POLICYEXPENSEHEADS
            }))
        }
        else {
            setPolicyHistory((prev) => ({
                ...prev,
                expenseHeadHorde: ""
            }))
        }

        if (EMPEMPLOYGRADES !== null) {
            setPolicyHistory((prev) => ({
                ...prev,
                emplevelHorde: EMPEMPLOYGRADES
            }))
        }
        else {
            setPolicyHistory((prev) => ({
                ...prev,
                emplevelHorde: ""
            }))
        }


    }, [transformedPolicyData])









    //useEffect for history's expenseHead selectbox and empLevel selectbox

    useEffect(() => {
        const FirstPolicyHistorySet = async () => {
            if (policyHistory?.expenseHeadHorde?.length > 0) {
                const DummyObj = {

                    exp_heads: "All",
                };

                const isAnyOptionPresent = policyHistory &&
                    policyHistory.expenseHeadHorde.some(
                        (type) => type.exp_heads === "All"
                    );

                if (!isAnyOptionPresent) {
                    setPolicyHistory((prev) => ({
                        ...prev,

                        expenseHeadHorde: [DummyObj, ...prev.expenseHeadHorde],
                        sltdExpense_head: DummyObj.exp_heads,
                    }));
                }
            }
        };
        FirstPolicyHistorySet();
    }, [policyHistory.expenseHeadHorde]);

    // useEffect(() => {
    //     const isAllOptionPresent = Array.isArray(policyHistory.expenseHeadHorde) &&
    //         policyHistory.expenseHeadHorde.some(
    //             type => type.exp_heads === "All"
    //         );
    //     if (isAllOptionPresent) {
    //         setPolicyHistory((prev) => ({
    //             ...prev,
    //             sltdExpense_head: "All",
    //         }));
    //     }
    //     else {
    //         setPolicyHistory((prev) => ({
    //             ...prev,
    //             sltdExpense_head: "",
    //         }));
    //     }

    // }, [policyHistory.expenseHeadHorde])



    useEffect(() => {
        const FirstHistoryEmpLevels = async () => {
            if (policyHistory?.emplevelHorde?.length > 0) {
                const DummyObj = {

                    emp_levels: "All",
                };

                const isAnyOptionPresent = policyHistory &&
                    policyHistory.emplevelHorde.some(
                        (type) => type.emp_levels === "All"
                    );

                if (!isAnyOptionPresent) {
                    setPolicyHistory((prev) => ({
                        ...prev,

                        emplevelHorde: [DummyObj, ...prev.emplevelHorde],
                        sltdEmp_level: DummyObj.emp_levels,
                    }));
                }
            }
        };
        FirstHistoryEmpLevels();
    }, [policyHistory.emplevelHorde])



    //filtered gridData for history

    useEffect(() => {

        let filteredData = [];

        if (policyHistory.sltdExpense_head === "All" && policyHistory.sltdEmp_level === "All") {
            // Scenario 1: Both are "All", no filtering needed
            filteredData = transformedPolicyData;
            const totalAmount = filteredData && filteredData.reduce((sum, currentItem) => {
                return sum + currentItem.amount_eligible;
            }, 0);

            if (totalAmount !== 0) {
                setPolicyHistory(prev => ({ ...prev, GrandTotal: totalAmount }))
            }
            else {
                setPolicyHistory(prev => ({ ...prev, GrandTotal: "" }))
            }
        } else if (policyHistory.sltdExpense_head !== "All" && policyHistory.sltdEmp_level === "All") {
            // Scenario 2: Expense_head is not "All" and Emp_level is "All"
            filteredData = transformedPolicyData && transformedPolicyData?.filter(item => item.expense_head === policyHistory.sltdExpense_head);
        } else if (policyHistory.sltdExpense_head === "All" && policyHistory.sltdEmp_level !== "All") {
            // Scenario 3: Expense_head is "All" and Emp_level is not "All"
            filteredData = transformedPolicyData && transformedPolicyData?.filter(item => item.employee_level === policyHistory.sltdEmp_level);
        } else {
            // Scenario 4: Both are not "All"
            filteredData = transformedPolicyData && transformedPolicyData?.filter(item =>
                item.expense_head === policyHistory.sltdExpense_head &&
                item.employee_level === policyHistory.sltdEmp_level
            );
        }


        setPolicyHistory(prev => ({
            ...prev,
            gridData: filteredData !== null ? filteredData : ""
        }));



    }, [policyHistory.sltdExpense_head, policyHistory.sltdEmp_level, transformedPolicyData])




    useEffect(() => {
        const totalAmountEligible = policyHistory.gridData && policyHistory.gridData.reduce((sum, currentItem) => {
            return sum + currentItem.amount_eligible;
        }, 0);

        if (totalAmountEligible !== 0) {
            setPolicyHistory(prev => ({ ...prev, sumeligible: totalAmountEligible }))
        }
        else {
            setPolicyHistory(prev => ({ ...prev, sumeligible: "" }))
        }

    }, [policyHistory.gridData])


    //chart for policy
    function customizeTooltip(arg) {
        return {
            text: `${arg.seriesName} years: ${arg.valueText}`,
        };
    }



    //useEffect for barchart..

    // useEffect(() => {
    //     const filteredArray = transformedPolicyData && transformedPolicyData?.reduce((acc, currentItem) => {
    //         // acc is accumulator and currentItem is iterating element of this array. here currentItem initially is first object of the array
    //         const { expense_head, sub_expense, amount_eligible } = currentItem;

    //         const existingItem = acc.find(item => item.expense_head === currentItem.expense_head);

    //         if (existingItem) {
    //             // If an item with the same expense_head exists, add the new sub_expense and amount_eligible
    //             existingItem[sub_expense] = amount_eligible;
    //         } else {
    //             // If no item with the same expense_head exists, create a new item
    //             const newItem = {
    //                 expense_head, // Set the expense_head property
    //                 [sub_expense]: amount_eligible // Computed property name
    //             };
    //             acc.push(newItem);
    //         }

    //         return acc;
    //     }, []);

    //     setbarchartarray(filteredArray)


    // }, [transformedPolicyData]);



    useEffect(() => {
        if (transformedPolicyData) {
            const filteredArray = transformedPolicyData && transformedPolicyData?.reduce((acc, currentItem) => {
                const { expense_head, sub_expense, amount_eligible } = currentItem;

                const existingItemIndex = acc.findIndex(item => item.expense_head === expense_head);

                if (existingItemIndex !== -1) {
                    // If an item with the same expense_head exists, create a new object with updated values
                    const updatedItem = {
                        ...acc[existingItemIndex],
                        [sub_expense]: amount_eligible
                    };
                    // Replace the existing item with the updated one
                    acc[existingItemIndex] = updatedItem;
                } else {
                    // If no item with the same expense_head exists, create a new item
                    const newItem = {
                        expense_head,
                        [sub_expense]: amount_eligible
                    };
                    acc.push(newItem);
                }

                return acc;
            }, []);

            setbarchartarray(filteredArray);
        }
    }, [transformedPolicyData]);

    //console.log(barchartarray, "barchartARRAY")



    // for (let i = 0; i < barchartarray?.length; i++) {
    //     const series = Object.keys(barchartarray[i]).map((key, index) => {
    //         if (key !== 'expense_head') {
    //             return (
    //                 <Series
    //                     key={`${i}-${index}`}
    //                     valueField={key}
    //                     name={key}
    //                 />
    //             );
    //         }
    //         return null;
    //     });

    //     seriesComponents.push(series);
    // }

    // const chartData = barchartarray?.map(item => {
    //     const seriesData = [];
    //     for (const [key, value] of Object.entries(item)) {
    //         if (key !== 'expense_head') {
    //             seriesData.push({
    //                 argument: item.expense_head, // Set the argument field for each data point
    //                 value: value,
    //                 stack: key // Use the sub-expense key as the stack identifier
    //             });
    //         }
    //     }
    //     return {
    //         ...item,
    //         data: seriesData // Include the processed series data in each item
    //     };
    // });
    //console.log(seriesComponents,"SERIES COMPONENTS")





    const renderSerialNumber = (data) => {
        const pageIndex = data.component.pageIndex();
        const pageSize = data.component.pageSize();
        const rowIndex = data.rowIndex + 1 + pageIndex * pageSize;
        return <span>{rowIndex}</span>;
    }


    // update a policy
    const handleRowUpdate = (e) => {


        try {

            setShowUpdateButton(true)

            const updatedRow = {
                policy_code: e.data.policy_code,
                sub_expense: e.data.sub_expense,
                amount_eligible: e.data.amount_eligible,
                policy_date: e.data.policy_date,
            };
            console.log(updatedRow,"updated Row")
            // Check if an object with the same expense_code already exists
            const existingIndex = formDataforUpdate.findIndex(row => row.policy_code === updatedRow.policy_code);
            console.log(existingIndex,"existing index")
            if (existingIndex !== -1) {
                // If the object exists, update it
                setFormDataForUpdate(prevState => {
                    const updatedFormData = [...prevState];
                    updatedFormData[existingIndex] = updatedRow;
                    return updatedFormData;
                });
            } else {
                // If the object does not exist, add it to the formDataForUpdate state
                setFormDataForUpdate(prevState => [...prevState, updatedRow]);
            }

        }
        catch (error) {
            console.error("Failed to update:", error);
        }
    }

    console.log(formDataforUpdate,"formDataforUpdate")


    const handleFinalUpdate = () => {
        try {
            //setUpdateChecker(true)
            mutateupdatePolicy.mutate(formDataforUpdate)

        }
        catch (error) {
            console.error("Failed to update:", error);
        }
    }
    const updateBulkPolicys = (formDataforUpdate) => UPDATEBULKPOLICYS(UPDATE_BULK_POLICYS(), formDataforUpdate);

    const mutateupdatePolicy = useMutation({
        mutationKey: ['updateBulkPolicys'],
        mutationFn: () => updateBulkPolicys(formDataforUpdate),
        onSuccess: (data) => {
            queryClient.invalidateQueries({
                queryKey: ["getAllPolicys"],
            });

            setCounter(counter + 1)
            //setUpdateExptopolicyselectbox(updateExptopolicyselectbox + 1)
         //   alert(data.message, "success", 3000);
            setShowUpdateButton(false)

        },
        onError: (error) => {
            console.error("Error fetching single profile:", error);
            //alert("Did not update profile")
            setShowUpdateButton(false)
        }
    });

    
    // seriesComp= barchartarray && barchartarray.map((item, index) => (
    //     Object.keys(item).map((key, keyIndex) => {
    //         if (key !== 'expense_head') {
    //             return (
    //                 <Series
    //                     key={`${index}-${key}`}
    //                     valueField={key}
    //                     name={key}
    //                 />
    //             );
    //         }
    //         return null;
    //     }).filter(series => series !== null) // Filter out null values (for 'expense_head')
    // ))}

    // const series = useMemo(() => {
    //     if (!barchartarray) return [];
        
    //     return barchartarray.map((item, index) => {
    //         return Object.keys(item).map((key, keyIndex) => {
    //             if (key !== 'expense_head') {
    //                 return (
    //                     <Series
    //                         key={`${index}-${key}`}
    //                         valueField={key}
    //                         name={`${key}-${index}`}
    //                     />
    //                 );
    //             }
    //             return null;
    //         }).filter(series => series !== null);
    //     });
    // }, [barchartarray]);

    // const generateSeries = (item, index) => {
    //     const processedKeys = new Set(); // Set to store processed keys for each item
    //     const series = [];

    //     Object.keys(item).forEach((key) => {
    //         if (key !== 'expense_head' && !processedKeys.has(key)) {
    //             series.push(
    //                 <Series
    //                     key={`${index}-${key}`}
    //                     valueField={key}
    //                     name={key}
    //                 />
    //             );
    //             processedKeys.add(key); // Add the processed key to the set
    //         }
    //     });

    //     return series;
    // };

    let seriesComp = barchartarray && barchartarray.map((item, index) => {
        let series = [];
        Object.keys(item).forEach((key, keyIndex) => {
            if (key !== 'expense_head') {
                series.push(
                    <Series
                        key={`${index}-${key}`}
                        valueField={key}
                        name={key}
                    />
                );
            }
            // If it's the last key, stop the iteration
            if (keyIndex === Object.keys(item).length - 1) {
                return;
            }
        });
        return series;
    });


    return (
        <>

            <div className="row mt-3 d-flex m-3">
                <div className="col-lg-8 shadow bg-white p-3">
                    <div className="d-flex">
                        <SelectBox
                            label="Select Expense Head:"
                            labelMode="floating"
                            dataSource={policyHistory.expenseHeadHorde || []}
                            displayExpr="exp_heads"
                            valueExpr="exp_heads"

                            onValueChanged={(e) =>
                                setPolicyHistory((prev) => ({
                                    ...prev,
                                    sltdExpense_head: e.value,
                                }))
                            }
                            value={policyHistory.sltdExpense_head}
                        />
                        <SelectBox
                            label="Select Employee Level:"
                            className="ms-3"
                            labelMode="floating"
                            dataSource={policyHistory.emplevelHorde || []}
                            displayExpr="emp_levels"
                            valueExpr="emp_levels"

                            onValueChanged={(e) =>
                                setPolicyHistory((prev) => ({
                                    ...prev,
                                    sltdEmp_level: e.value,
                                }))
                            }
                            value={policyHistory.sltdEmp_level}
                        />
                    </div>

                    <DataGrid
                        className={'content-block px-0 empGridWidth'}
                        dataSource={policyHistory?.gridData || []}
                        //dataSource={""}
                        showBorders={true}
                        //onRowInserting={(e) => handleRowInsert(e)}
                        //onRowUpdated={(e) => handleRowUpdate(e)}
                        defaultPageSize={6}
                        onRowUpdated={(e) => handleRowUpdate(e)}
                        paging={{ pageSize: 6 }}
                        rowAlternationEnabled={true}
                        selectionFilter={true}

                    >
                        <Editing
                            mode="row"
                            //allowAdding={true}
                            allowDeleting={true}
                            allowUpdating={true}

                        />
                        <Column caption="Sno" cellRender={renderSerialNumber} width={70} alignment="center" allowEditing={false} />
                        <Column dataField="policy_code" caption="Policy Code" visible={false} allowEditing={false} />
                        <Column dataField="expense_head" caption="Expense Head" allowEditing={false} />
                        <Column dataField="expense_code" caption="Expense Code" visible={false} allowEditing={false} />
                        <Column dataField="employee_level" caption="Employee Level" allowEditing={false} />
                        <Column dataField="sub_expense" caption="Cost Center/Sub-Expense" allowEditing={true} />
                        <Column dataField="amount_eligible" caption="Eligible amount" allowEditing={true} />
                        <Column dataField="policy_date" caption="Policy Date" allowEditing={true} />


                    </DataGrid>
                    {showUpdateButton && <button className='employBtn' onClick={handleFinalUpdate}>Final Update</button>}
                </div>
                <div className="col-lg-4 shadow bg-white p-3">
                    <div className="">
                        <CircularGauge
                            id="gauge"
                            value={policyHistory.sumeligible || 0}
                        >
                            <Scale
                                startValue={0}
                                endValue={
                                    Math.max(policyHistory.GrandTotal, policyHistory.sumeligible) || 0
                                }
                            //tickInterval={10}
                            >
                                <Label useRangeColors={true} />
                            </Scale>
                            <RangeContainer palette="Pastel">
                                <Range
                                    startValue={0}
                                    endValue={90}
                                />
                                <Range
                                   startValue={90}
                                   endValue={policyHistory.sumeligible || 0}/>
                                <Range
                                    startValue={policyHistory.sumeligible || 0}
                                    endValue={policyHistory.GrandTotal || 0}
                                />

                            </RangeContainer>
                            <Title text={`Eligible Amount vs Grand Total for Expense Head - "${policyHistory.sltdExpense_head}" and Employee-Level - "${policyHistory.sltdEmp_level}"`}>

                                <Font size={18} />
                            </Title>
                            <Export enabled={true} />

                            <Legend
                                visible={true}
                                verticalAlignment="bottom"
                                horizontalAlignment="center"
                            />
                        </CircularGauge>
                        <div>

                            {/* <div>Sum Eligible: {policyHistory.sumeligible || 0}</div>
                <div>Grand Total: {policyHistory.GrandTotal || 0}</div>
                <div>Balance: {(policyHistory.GrandTotal -policyHistory.sumeligible) || 0}</div> */}
                            <div style={{ textAlign: 'center', marginTop: '20px' }}>
                                <div style={{ color: 'green' }}>Sum Eligible: {policyHistory.sumeligible}</div>
                                <div style={{ color: 'black' }}>Grand Total: {policyHistory.GrandTotal}</div>
                                <div style={{ color: '#ffd700' }}>Balance: {(policyHistory.GrandTotal - policyHistory.sumeligible) || 0}</div>
                            </div>


                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}