import appAxios from "./axiosInterceptors";
import { BASEPATH } from "../config/config";
import axios from "axios";
import { getAPIKey } from "./getApiKey";


const makeGetRequest = async (endpoint) => {
  const { data } = await appAxios.get(endpoint);
  return data;
};

const makePostRequest = async (endpoint, body) => {
  const { data } = await appAxios.post(endpoint, body);
  //console.log(data, "data from axios")
  return data;
};

const makeGetRequestwithParams = async (endpoint) => {
  const { data } = await appAxios.get(endpoint);
  //console.log(data, 'data');
  return data;
};

//policy and expenses
const INSERT_EXPENSES = async (endpoint, newData) => {
  const apiKey = getAPIKey();

  const apiKeyObject = JSON.parse(apiKey);
  let databasename = "";
  if (apiKeyObject) {
    databasename = apiKeyObject?.databasename;
  }
  //console.log(endpoint,"endpoint")
  //console.log(newData,"newData")
  const { data } = await axios({
    method: "post",
    url: `${BASEPATH}${endpoint}`,

    data: JSON.stringify({
      expenses: newData,
    }),

    headers: {
      "Content-Type": "application/json",
      "Database-Name": databasename,
    },
  });
  //console.log(data, "expense data from backend")
  return data;
};

const GETEXPENSES = async (endpoint) => {
  const apiKey = getAPIKey();

  const apiKeyObject = JSON.parse(apiKey);
  let databasename = "";
  if (apiKeyObject) {
    databasename = apiKeyObject?.databasename;
  }
  //console.log(endpoint,"endpoint")

  const { data } = await axios({
    method: "get",
    url: `${BASEPATH}${endpoint}`,
    headers: {
      "Content-Type": "application/json",
      "Database-Name": databasename,
    },
  });
  //console.log(data, "GETALLEXPENSES")
  return data;
};

const UPDATEBULKEXP = async (endpoint, newData) => {
  const apiKey = getAPIKey();

  const apiKeyObject = JSON.parse(apiKey);
  let databasename = "";
  if (apiKeyObject) {
    databasename = apiKeyObject?.databasename;
  }
  //console.log(endpoint,"endpoint")
  const { data } = await axios({
    method: "put",
    url: `${BASEPATH}${endpoint}`,
    data: JSON.stringify({
      newData,
    }),
    headers: {
      "Content-Type": "application/json",
      "Database-Name": databasename,
    },
  });
  //console.log(data, "bulkUPDATE EXPENSES")
  return data;
};

const GETEMPLEVELS = async (endpoint) => {
  const apiKey = getAPIKey();

  const apiKeyObject = JSON.parse(apiKey);
  let databasename = "";
  if (apiKeyObject) {
    databasename = apiKeyObject?.databasename;
  }
  //console.log(endpoint,"endpoint")

  const { data } = await axios({
    method: "get",
    url: `${BASEPATH}${endpoint}`,
    headers: {
      "Content-Type": "application/json",
      "Database-Name": databasename,
    },
  });
  //console.log(data, "GET emp levels")
  return data;
};

//cost center axios........

const INSERT_COSTCENTERS = async (endpoint, newData) => {
  try {
    const apiKey = getAPIKey();

    const apiKeyObject = JSON.parse(apiKey);
    let databasename = "";
    if (apiKeyObject) {
      databasename = apiKeyObject?.databasename;
    }
    //console.log(endpoint,"endpoint")
    //console.log(newData,"newData")
    const { data } = await axios({
      method: "post",
      url: `${BASEPATH}${endpoint}`,

      data: JSON.stringify({
        costcenters: newData,
      }),

      headers: {
        "Content-Type": "application/json",
        "Database-Name": databasename,
      },
    });
    //console.log(data, "expense data from backend")
    return data;
  } catch (error) {
    throw error.response;
  }
};

const GETCOSTCENTERS = async (endpoint) => {
  try {
    const apiKey = getAPIKey();

    const apiKeyObject = JSON.parse(apiKey);
    let databasename = "";
    if (apiKeyObject) {
      databasename = apiKeyObject?.databasename;
    }
    //console.log(endpoint,"endpoint")

    const { data } = await axios({
      method: "get",
      url: `${BASEPATH}${endpoint}`,
      headers: {
        "Content-Type": "application/json",
        "Database-Name": databasename,
      },
    });
    //console.log(data, "GETALLEXPENSES")
    return data;
  } catch (error) {
    throw error.response;
  }
};

// POLICYS - AXIOS API

const INSERT_POLICY = async (endpoint, newData) => {
  const apiKey = getAPIKey();

  const apiKeyObject = JSON.parse(apiKey);
  let databasename = "";
  if (apiKeyObject) {
    databasename = apiKeyObject?.databasename;
  }
  const { data } = await axios({
    method: "post",
    url: `${BASEPATH}${endpoint}`,
    data: JSON.stringify({ newData }),
    headers: {
      "Content-Type": "application/json",
      "Database-Name": databasename,
    },
  });
  //console.log(data, "INSERTED A POLICY")
  return data;
};

const BULKINSERT_POLICY = async (endpoint, newData, params2) => {
  try {
    const apiKey = getAPIKey();

    const apiKeyObject = JSON.parse(apiKey);
    let databasename = "";
    if (apiKeyObject) {
      databasename = apiKeyObject?.databasename;
    }
    const { data } = await axios({
      method: "post",
      url: `${BASEPATH}${endpoint}`,
      data: JSON.stringify({
        formData: newData,
        expense_head: params2,
      }),
      headers: {
        "Content-Type": "application/json",
        "Database-Name": databasename,
      },
    });
    //console.log(data, "INSERTED BULK POLICY.......")
    return data;
  } catch (error) {
    throw error.response;
  }
};

const GETPOLICYS = async (endpoint) => {
  const apiKey = getAPIKey();

  const apiKeyObject = JSON.parse(apiKey);
  let databasename = "";
  if (apiKeyObject) {
    databasename = apiKeyObject?.databasename;
  }
  const { data } = await axios({
    method: "get",
    url: `${BASEPATH}${endpoint}`,
    headers: {
      "Content-Type": "application/json",
      "Database-Name": databasename,
    },
  });
  //console.log(data, "GETALL POLICYS policy")
  return data;
};

const UPDATEBULKPOLICYS = async (endpoint, newData) => {
  const apiKey = getAPIKey();

  const apiKeyObject = JSON.parse(apiKey);
  let databasename = "";
  if (apiKeyObject) {
    databasename = apiKeyObject?.databasename;
  }
  //console.log(endpoint,"endpoint")
  const { data } = await axios({
    method: "put",
    url: `${BASEPATH}${endpoint}`,
    data: JSON.stringify({
      newData,
    }),
    headers: {
      "Content-Type": "application/json",
      "Database-Name": databasename,
    },
  });
  //console.log(data, "bulkUPDATE POLICY..")
  return data;
};

//employee api
const GETONEEMPLOYEE = async (endpoint) => {
  const apiKey = getAPIKey();

  const apiKeyObject = JSON.parse(apiKey);
  let databasename = "";
  if (apiKeyObject) {
    databasename = apiKeyObject?.databasename;
  }
  const { data } = await axios({
    method: "get",
    url: `${BASEPATH}/${endpoint}`,
    headers: {
      "Content-Type": "application/json",
      "Database-Name": databasename,
    },
  });
  return data;
};

// profile module
// fetch for getting single profile at emp_id
const GETONEPROFILE = async (endpoint) => {
  const apiKey = getAPIKey();

  const apiKeyObject = JSON.parse(apiKey);
  let databasename = "";
  if (apiKeyObject) {
    databasename = apiKeyObject?.databasename;
  }
  const { data } = await axios({
    method: "get",
    url: `${BASEPATH}${endpoint}`,

    headers: {
      "Content-Type": "application/json",
      "Database-Name": databasename,
    },
  });
  //console.log(data, "data from backend")
  return data;
};

const UPDATEONEPROFILE = async (endpoint, formDataprofile1) => {
  const apiKey = getAPIKey();
  console.log(endpoint, "endpoint");
  console.log(formDataprofile1, "formDataprofile1 for updating...");

  const apiKeyObject = JSON.parse(apiKey);
  let databasename = "";
  if (apiKeyObject) {
    databasename = apiKeyObject?.databasename;
  }
  const { data } = await axios({
    method: "put",
    url: `${BASEPATH}/${endpoint}`,
    data: formDataprofile1,

    headers: {
      "Content-Type": "multipart/form-data",
      "Database-Name": databasename,
    },
  });
  //console.log(data, "updated data from backend profile")
  return data;
};

//another way of doing makepostrequest
// const makePostRequest = async (endpoint, { body, params }) => {
//     try {
//         const { data } = await appAxios.post(endpoint, body, { params });
//         return data;
//     } catch (error) {
//         throw error;
//     }
// };

const makePatchRequest = async (endpoint, body) => {
  const { data } = await appAxios.patch(endpoint, body);
  return data;
};

const makeDeleteRequest = async (endpoint) => {
  const { data } = await appAxios.delete(endpoint);
  return data;
};
export {
  makeGetRequest,
  makePostRequest,
  makePatchRequest,
  makeDeleteRequest,
  makeGetRequestwithParams,
  GETONEEMPLOYEE,
  GETONEPROFILE,
  UPDATEONEPROFILE,
  INSERT_EXPENSES,
  GETEXPENSES,
  UPDATEBULKEXP,
};
export {
  GETEMPLEVELS,
  INSERT_POLICY,
  GETPOLICYS,
  BULKINSERT_POLICY,
  UPDATEBULKPOLICYS,
  INSERT_COSTCENTERS,
  GETCOSTCENTERS,
};
