import React from "react";
import { useState, useEffect, useRef } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { TextBox } from "devextreme-react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import DataGrid, {
  Column,
  Editing,
  Popup,
  Paging,
  Scrolling,
  Form,
  SearchPanel,
  Export,
  Selection,
} from "devextreme-react/data-grid";
import {
  UPDATE_BULK_EXPENSES,
  BULK_EXPENSES_UPLOAD,
  GET_ALL_EXPENSES,
} from "../../api/api";
import {
  UPDATEBULKEXP,
  INSERT_EXPENSES,
  GETEXPENSES,
} from "../../api/requestMethods";
import notify from "devextreme/ui/notify";
import { SUCCESS, DURATION } from "../../constants/constants.ts";

export default function Expenses() {
  const [show, setShow] = useState(false);
  const [expenseHeads, setExpenseHeads] = useState([]);
  const [showUpdateButton, setShowUpdateButton] = useState(false);
  const [updateExptopolicyselectbox, setUpdateExptopolicyselectbox] =
    useState(0);
  const [formDataforUpdate, setFormDataForUpdate] = useState([]);
  const [formDataFinal, setFormDataFinal] = useState([]);
  const queryClient = useQueryClient();

  const handleShow = () => {
    setShow(true);
    setExpenseHeads([]);
  };

  const handleClose = () => {
    setFormDataFinal([]);
    setExpenseHeads([]);
    setShow(false);
  };

  const handleAddExpenseHead = () => {
    setExpenseHeads((prevState) => [
      ...prevState,
      { number: prevState.length + 1, expense_head: "" },
    ]);
  };

  const handleSubtractExpenseHead = () => {
    if (expenseHeads.length > 0) {
      const updatedExpenseHeads = [...expenseHeads];
      updatedExpenseHeads.pop();
      setExpenseHeads(updatedExpenseHeads);
    }
  };

  const handleExpenseHeadChange = (index, value) => {
    setExpenseHeads((prevState) => {
      const updatedExpenseHeads = [...prevState];
      updatedExpenseHeads[index].expense_head = value;
      return updatedExpenseHeads;
    });
  };

  const handleResetExpenseHead = () => {
    setExpenseHeads([]);
  };

  const handleInsertExpenses = () => {
    const nonEmptyExpenseHeads =
      expenseHeads &&
      expenseHeads.filter(
        (head) => head.expense_head !== null && head.expense_head !== ""
      );
    if (nonEmptyExpenseHeads.length === 0) {
      return;
    }
    setFormDataFinal(nonEmptyExpenseHeads);
    postmutation.mutate(formDataFinal);
  };

  const fetchExpenses = async () => await GETEXPENSES(GET_ALL_EXPENSES());
  const { data: allExpenses } = useQuery({
    queryKey: ["getAllExpenses"],
    queryFn: () => fetchExpenses(),
  });

  const insertData = (formDataFinal) =>
    INSERT_EXPENSES(BULK_EXPENSES_UPLOAD(), formDataFinal);
  const postmutation = useMutation({
    mutationKey: ["insertExpense"],
    mutationFn: () => insertData(formDataFinal),
    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: ["getAllExpenses"],
      });
      handleClose();
    },
    onError: (error) => {
      console.error("Error inserting data:", error);
      handleClose();
    },
  });

  const renderSerialNumber = (data) => {
    const pageIndex = data.component.pageIndex();
    const pageSize = data.component.pageSize();
    const rowIndex = data.rowIndex + 1 + pageIndex * pageSize;
    return <span>{rowIndex}</span>;
  };

  const handleRowUpdate = (e) => {
    try {
      setShowUpdateButton(true);
      const updatedRow = {
        expense_code: e.data.expense_code,
        expense_head: e.data.expense_head,
      };
      const existingIndex = formDataforUpdate.findIndex(
        (row) => row.expense_code === updatedRow.expense_code
      );
      if (existingIndex !== -1) {
        const updatedFormData = [...formDataforUpdate];
        updatedFormData[existingIndex] = updatedRow;
        setFormDataForUpdate(updatedFormData);
      } else {
        setFormDataForUpdate((prevState) => [...prevState, updatedRow]);
      }
    } catch (error) {
      console.error("Failed to update:", error);
    }
  };

  const handleFinalUpdate = () => {
    try {
      mutateupdateexp.mutate(formDataforUpdate);
    } catch (error) {
      console.error("Failed to update:", error);
    }
  };

  //tanstack for bulk updating expenses-
  const updateBulkExpenses = (formDataforUpdate) =>
    UPDATEBULKEXP(UPDATE_BULK_EXPENSES(), formDataforUpdate);

  const mutateupdateexp = useMutation({
    mutationKey: ["updateBulkExp"],
    mutationFn: () => updateBulkExpenses(formDataforUpdate),
    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: ["getAllExpenses"],
      });

      setUpdateExptopolicyselectbox(updateExptopolicyselectbox + 1);
      notify(data.message, SUCCESS, DURATION);
      setShowUpdateButton(false);
    },
    onError: (error) => {
      console.error("Error fetching single profile:", error);
      setShowUpdateButton(false);
    },
  });

  return (
    <>


      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add Expenses</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            {expenseHeads &&
              expenseHeads.map((expenseHead, index) => (
                <div key={index}>
                  <TextBox
                    value={expenseHead.expense_head}
                    onValueChanged={(e) =>
                      handleExpenseHeadChange(index, e.value)
                    }
                    placeholder="Enter expense head"
                  />
                </div>
              ))}
            <button className="employBtn" onClick={handleAddExpenseHead}>
              +
            </button>
            <br></br>
            <br></br>
            <button className="employBtn" onClick={handleSubtractExpenseHead}>
              -
            </button>
            <br></br>
            <br></br>
            <button className="employBtn" onClick={handleResetExpenseHead}>
              Clear All
            </button>
            <br />

        </div>




    </Modal.Body>
    <Modal.Footer>
       
        <Button variant="primary" onClick={handleInsertExpenses}>
            Insert
        </Button>
    </Modal.Footer>
</Modal>
<div className="row d-flex mt-3 gap-3 mx-auto">
    <div className=" shadow bg-white p-3">
    <button className='employBtn' onClick={handleShow}>Add Expenses</button>

        <div className="">

            <DataGrid
                className={'content-block px-0 empGridWidth'}
                dataSource={allExpenses?.data || []}
                //dataSource={""}
                showBorders={true}
                //onRowInserting={(e) => handleRowInsert(e)}
               
                defaultPageSize={7}
                onRowUpdated={(e) => handleRowUpdate(e)}
                paging={{ pageSize: 7 }}
                rowAlternationEnabled={true}
                selectionFilter={true}

            >
              <Editing mode="row" allowDeleting={true} allowUpdating={true} />
              <Column
                caption="#"
                cellRender={renderSerialNumber}
                width={80}
                alignment="center"
                allowEditing={false}
              />
              <Column
                dataField="expense_code"
                caption="Expense Code"
                visible={false}
                allowEditing={false}
              />
              <Column
                dataField="expense_head"
                caption="Expense Head"
                width={20}
              />
            </DataGrid>
            {showUpdateButton && (
              <button className="employBtn" onClick={handleFinalUpdate}>
                Update
              </button>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
