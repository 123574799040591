import React, { useMemo, useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ContextMenu, { Position } from "devextreme-react/context-menu";
import List from "devextreme-react/list";
import { useAuth } from "../../contexts/auth";
import "./UserPanel.scss";
import userInfo from "../../utils/default-user";
import { BASEPATH } from "../../config/config";
import axios from "axios";
import { GETONEPROFILE } from "../../api/requestMethods";
import { GET_SINGLE_PROFILE } from "../../api/api";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

export default function UserPanel({ menuMode, counteruserpanel }) {
  const { user, signOut } = useAuth();
  const [imageURL, setImageURL] = useState("");
  const navigate = useNavigate();
  const navigateToProfile = useCallback(() => {
    navigate("/profile");
  }, [navigate]);
  const menuItems = useMemo(
    () => [
      {
        text: "Profile",
        icon: "user",
        onClick: navigateToProfile,
      },
      {
        text: "Logout",
        icon: "runner",
        onClick: signOut,
      },
    ],
    [navigateToProfile, signOut]
  );

  //tanstack
  useEffect(() => {
    mutationgetprofile.mutate(user.id);
  }, [counteruserpanel]);

  const fetchSingleProfile = (emp_id) =>
    GETONEPROFILE(GET_SINGLE_PROFILE(emp_id));
  const mutationgetprofile = useMutation({
    mutationKey: ["fetchSingleProfile1"],
    mutationFn: (emp_id) => fetchSingleProfile(emp_id),
    onSuccess: (data) => {
      if (data !== null) {
        setImageURL(data.file);
      } else {
        setImageURL("");
      }
    },
    onError: (error) => {
      console.error("Error fetching single profile:", error);
      alert("Did not fetch profile");
    },
  });

  const fullImageURL = `${BASEPATH}${imageURL}`;
  return (
    <div className={"user-panel"}>
      <div className={"user-info"}>
        <div className={"image-container"}>
          <img src={fullImageURL} className={"user-image"} />
        </div>
        <div className={"user-name"}>{user.username}</div>
      </div>

      {menuMode === "context" && (
        <ContextMenu
          items={menuItems}
          target={".user-button"}
          showEvent={"dxclick"}
          width={210}
          cssClass={"user-menu"}
        >
          <Position
            my={{ x: "center", y: "top" }}
            at={{ x: "center", y: "bottom" }}
          />
        </ContextMenu>
      )}
      {menuMode === "list" && (
        <List className={"dx-toolbar-menu-action"} items={menuItems} />
      )}
    </div>
  );
}
