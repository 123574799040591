import React from "react";
import { useState, useEffect, useRef, } from 'react';

import Button from 'react-bootstrap/Button';
import { TextBox } from 'devextreme-react';

import { DateBox, SelectBox } from 'devextreme-react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { GET_ALL_EXPENSES, GET_EMP_LEVELS, BULK_INSERT_POLICY, GET_ALL_COSTCENTERS } from "../../../api/api";
import { GETEXPENSES, GETEMPLEVELS, BULKINSERT_POLICY, GETCOSTCENTERS } from "../../../api/requestMethods";



export default function Apply() {


    const [formData, setFormData] = useState([]);
    const [insertpolicyaxios, setInsertPolicyAxios] = useState([])

    const [counterExpenseFetch, setCounterExpenseFetch] = useState(0)


    const [insertpolicychecker, setInsertPolicyChecker] = useState(false)
    const [policyDataFinal, setPolicyDataFinal] = useState({})
    const queryClient = useQueryClient()


    const [updateExptopolicyselectbox, setUpdateExptopolicyselectbox] = useState(0)
    const [policyCollection, setPolicyCollection] = useState({
        expenseHeadsTypes: "",
        employeeLevels: "",
        selectedExpense: "",
        selectedEmployeeLevel: "",
        amount_eligible: "",
        policy_date: "",
        sub_expense: "",
        cost_center_array: [],
    })


    const [tableArray, setTableArray] = useState([])

    const [errorz, setErrorz] = useState([])



    const handleClose = () => {


        setInsertPolicyChecker(false)
        setPolicyDataFinal({})
        setPolicyCollection(prev => ({
            ...prev,
            amount_eligible: "",
            policy_date: "",
            sub_expense: ""
        }));
        setCounterExpenseFetch(counterExpenseFetch + 1);
        //setErrors({})
        setInsertPolicyAxios([])
        setFormData([])
        setCheckboxVisibility([])
        setErrorz([])
    }






    const fetchExpenses = async () => await GETEXPENSES(GET_ALL_EXPENSES());
    const { data: allExpenses, isError, error, isLoading, isPlaceholderData, } = useQuery({
        queryKey: ["getAllExpenses"],
        queryFn: () => fetchExpenses(),
        // staleTime: 60 * 1000 * 5
    });

    const fetchCostCenters = async () => await GETCOSTCENTERS(GET_ALL_COSTCENTERS());
    const { data: allCostCenters, isError_CC, error_CC, isLoading_CC, isPlaceholderData_CC, } = useQuery({
        queryKey: ["getAllCostCenters"],
        queryFn: () => fetchCostCenters(),
        // staleTime: 60 * 1000 * 5
    });

    console.log(allCostCenters.data, "all cost centers in Apply Polciy")







    const handleExpenseChange = (value) => {
        setPolicyCollection(prev => ({
            ...prev,
            selectedExpense: value
        }));
    }

    useEffect(() => {

        const EXPTYPES = allExpenses && allExpenses?.data.map((cdata) => ({
            expense_head: cdata.expense_head,
        }))

        if (EXPTYPES !== null) {
            setPolicyCollection(prev => ({
                ...prev,
                expenseHeadsTypes: EXPTYPES
            }));
        }
        else {
            setPolicyCollection(prev => ({
                ...prev,
                expenseHeadsTypes: ""
            }));
        }

    }, [allExpenses, updateExptopolicyselectbox])




    // setting initial selctedexpense in policy
    useEffect(() => {
        const FirstExpenseHead = async () => {
            if (policyCollection && policyCollection.expenseHeadsTypes?.length > 0) {
                const DummyObj = {

                    expense_head: "All",
                };

                const isAnyOptionPresent = policyCollection && policyCollection.expenseHeadsTypes.some(
                    (type) => type.expense_head === "All"
                );

                if (!isAnyOptionPresent) {
                    setPolicyCollection((prev) => ({
                        ...prev,
                        //selectedTypes: DummyObj.objId,
                        //displayExpr: DummyObj.leaveTypeName,
                        expenseHeadsTypes: [DummyObj, ...prev.expenseHeadsTypes], // Append "Any" option. here ... operator is another word for add/insert. or use this-  ...spread operator creates a copy of whatev is after it, and then appends something after the comma
                        selectedExpense: DummyObj.expense_head,
                    }));
                }
            }
        };
        FirstExpenseHead();
    }, [policyCollection.expenseHeadsTypes]);


    //useeffect to fetch costcenters based on selectedExpense
    useEffect(() => {




    }, [policyCollection.selectedExpense])

    //to fetch employee levels for policy
    const fetchEmployeeLevels = async () => await GETEMPLEVELS(GET_EMP_LEVELS());
    const { data: allEmpLevels, isError2, error2, isLoading2, isPlaceholderData2, } = useQuery({
        queryKey: ["getEmpLevels"],
        queryFn: () => fetchEmployeeLevels(),
        // staleTime: 60 * 1000 * 5
    });



    useEffect(() => {

        if (allEmpLevels && allEmpLevels.emp_levels) {
            setTableArray(allEmpLevels.emp_levels)
        }
        else {
            setTableArray([])
        }
    }, [allEmpLevels])




    useEffect(() => {

        const EMPLEVELS = allEmpLevels && allEmpLevels?.emp_levels.map((cdata) => ({
            emp_level: cdata.employee_level,
        }))

        if (EMPLEVELS !== null) {
            setPolicyCollection(prev => ({
                ...prev,
                employeeLevels: EMPLEVELS
            }));
        }
        else {
            setPolicyCollection(prev => ({
                ...prev,
                employeeLevels: ""
            }));
        }

    }, [allEmpLevels])

    useEffect(() => {
        const FirstEmployeeLevel = async () => {
            if (policyCollection && policyCollection.employeeLevels?.length > 0) {
                const DummyObj = {

                    emp_level: "All",
                };

                const isAnyOptionPresent = policyCollection.employeeLevels.some(
                    (type) => type.emp_level === "All"
                );

                if (!isAnyOptionPresent) {
                    setPolicyCollection((prev) => ({
                        ...prev,
                        //selectedTypes: DummyObj.objId,
                        //displayExpr: DummyObj.leaveTypeName,
                        employeeLevels: [DummyObj, ...prev.employeeLevels],
                        selectedEmployeeLevel: DummyObj.emp_level,
                    }));
                }
            }
        };
        FirstEmployeeLevel();
    }, [policyCollection.employeeLevels]);








    //changes......
    // initial setState if useState is an array !
    // useEffect(() => {
    //     if (allEmpLevels && allEmpLevels.emp_levels) {
    //         setFormData(allEmpLevels.emp_levels.map((level) => ({
    //             employee_level: '',
    //             amount_eligible: '',
    //             sub_expense: '',
    //             policy_date: ''
    //         })));
    //     }
    // }, [allEmpLevels]);

    // console.log(formData, "formData")


    //const [checkboxVisibility, setCheckboxVisibility] = useState([]);
    const [checkboxVisibility, setCheckboxVisibility] = useState(Array(allEmpLevels?.emp_levels.length || 0).fill(false));
    // Ensure allEmpLevels is defined before setting checkbox visibility
    // useEffect(() => {
    //     if (allEmpLevels && allEmpLevels.emp_levels) {
    //         setCheckboxVisibility(Array(allEmpLevels.emp_levels.length).fill(false));
    //     }
    // }, [allEmpLevels]);

    const handleInputChange = (index, fieldName, value, value2) => {
        const newData = [...formData];
        const newCheckboxVisibility = [...checkboxVisibility];
        const newErrors = [...errorz];

        console.log(index, "current index")
        console.log(fieldName, "current Fieldname")
        console.log(value, "current value")
        if (fieldName === 'employee_level') {
            // Update checkbox visibility only for the current index
            newCheckboxVisibility[index] = value;

            // Check if checkbox is checked, and add employee_level to formData if true
            if (value) {
                newData[index] = {
                    ...newData[index],
                    [fieldName]: value2  // Use value2 (level.employee_level) as the value for employee_level
                };
            } else {
                // If checkbox is unchecked, remove corresponding entry from formData
                newData.splice(index, 1);
                newErrors.splice(index, 1)

            }
        }
        else {
            // For other fields, update corresponding entry in formData
            newData[index] = { ...newData[index], [fieldName]: value };
        }


        console.log(newData, "newData")
        setFormData(newData);
        setCheckboxVisibility(newCheckboxVisibility);
        setErrorz(newErrors);
    };



    const handleSubmit = () => {


        const newErrors = formData && formData.map(item => {
            const emptyFieldsInItem = [];

            if (!item) {
                // If item is undefined, do nothing
            } else {
                // if (!item.employee_level) {
                //     emptyFieldsInItem.push("employee_level");
                // }
                if (!item.amount_eligible) {
                    emptyFieldsInItem.push("amount_eligible");
                }
                if (!item.sub_expense) {
                    emptyFieldsInItem.push("sub_expense");
                }
                if (!item.policy_date) {
                    emptyFieldsInItem.push("policy_date");
                }
            }

            return emptyFieldsInItem;
        });

        console.log(newErrors, "empty Fields checker..");
        const allErrorsEmpty = newErrors.every(errorArray => errorArray.length === 0);
        console.log(allErrorsEmpty, "allErrorsEmpty check?")

        setErrorz(newErrors)
        if (allErrorsEmpty) {
            const filteredData = formData && formData.filter(entry => entry !== undefined);
            console.log(filteredData, "filteredData inside HANDLE SUBMIT")

            const allUnchecked = checkboxVisibility.every(value => value === false);
            const checkFormData = formData && formData.every(value => value === undefined || value === null)

            console.log(allUnchecked, "allUnchecked")
            console.log(checkFormData, "checkFormData")



            if (checkFormData) {
              //  alert("Select atleast one")
            }//
            else {
                console.log("At least one checkbox is checked");

                const formattedData = filteredData && filteredData.map(item => {
                    if (item.policy_date instanceof Date && !isNaN(item.policy_date)) {
                        return {
                            ...item,
                            policy_date: `${item.policy_date.getFullYear()}-${(item.policy_date.getMonth() + 1).toString().padStart(2, '0')}-${item.policy_date.getDate().toString().padStart(2, '0')}`
                        };
                    } else {
                        // If policy_date is not a valid date, handle it here (e.g., set it to null)
                        return {
                            ...item,
                            policy_date: null  // Or any other default value or handling
                        };
                    }
                });


                console.log(formattedData, "formattedData with date")
                setInsertPolicyAxios(formattedData)
                bulkPolicyMutation.mutate(insertpolicyaxios, policyCollection.selectedExpense)

            }
        }


        else {

            console.log("full of errors")
        }


    }



    const bulkinsertPolicy = (insertpolicyaxios, p2) => BULKINSERT_POLICY(BULK_INSERT_POLICY(), insertpolicyaxios, p2);
    const bulkPolicyMutation = useMutation({
        mutationKey: ['bulkinsertPolicy'],
        mutationFn: () => bulkinsertPolicy(insertpolicyaxios, policyCollection.selectedExpense),
        onSuccess: (data) => {

            queryClient.invalidateQueries({
                queryKey: ["getAllPolicys"],
            })
            alert(data.message, "success", 3000);
            // Remove matched rows from tableArray
            // const updatedTableArray = tableArray && tableArray.filter(tableRow => {
            //     // Check if employee_level of the table row exists in any of the insertpolicyaxios items
            //     return !insertpolicyaxios.some(policyItem => policyItem.employee_level === tableRow.employee_level);
            // });

            // console.log(updatedTableArray,"updated Table Array - on Success")

            // setTableArray(updatedTableArray);
            handleClose()

        },
        onError: (error) => {
            console.error("Error inserting data:", error);

            //alert("Did not insert alert box")
            console.log(error, "E")
          //  alert(error.data.message, "error", 3000);
            handleClose()
        }
    });

    // const handleCheckAll = (e) => {
    //     setCheckboxVisibility(Array(allEmpLevels?.emp_levels.length || 0).fill(true));
    //     console.log(e,"event")

    // };

    const handleCheckAll = () => {
        // Create a new array with all checkboxes checked
        const newCheckboxVisibility = Array(allEmpLevels?.emp_levels.length || 0).fill(true);

        // Create a new array for formData
        const newData = allEmpLevels?.emp_levels.map(level => ({
            employee_level: level.employee_level,
            // Add default values for other fields if needed
            amount_eligible: '',
            sub_expense: '',
            policy_date: ''
        }));

        // Update state with the new checkbox visibility and formData arrays
        setCheckboxVisibility(newCheckboxVisibility);
        setFormData(newData);
    };

    console.log(formData, "FormData inside HANDLE CHECK ALL")

    const handleUncheckAll = () => {
        setCheckboxVisibility(Array(allEmpLevels?.emp_levels.length || 0).fill(false));
        setFormData([])
    };

    console.log(insertpolicyaxios, "INSERT policy axios")
    console.log(tableArray, "tableArray- check after update")




    return (
        <>

            <div className=" shadow mt-3 bg-white p-3">
                <button className='employBtn' onClick={handleCheckAll}>Check All</button>
                <button className='employBtn ms-3' onClick={handleUncheckAll}>Uncheck All</button>
                <br></br><br></br>
                <SelectBox
                    label="Select Expense Head:"
                    labelMode="floating"
                    dataSource={policyCollection.expenseHeadsTypes || []}
                    displayExpr="expense_head"
                    valueExpr="expense_head"
                    className="w-25"
                    onValueChanged={(e) => handleExpenseChange(e.value)}
                    value={policyCollection.selectedExpense}
                />
                <br></br><br></br>
                <table className="table table-bordered">
                    <thead>
                        <tr>
                            <th className="w-25">Employee Level</th>
                            <th className="w-25">Amount Eligible</th>
                            <th className="w-25">Sub Expense</th>
                            <th className="w-25">Policy Date</th>
                        </tr>
                    </thead>
                    <tbody>
                        {tableArray && tableArray.map((level, index) => (
                            <tr key={index}>
                                <td>
                                    <label>
                                        <input
                                            type="checkbox"
                                            name="employee_level"
                                            className="me-3"
                                            checked={checkboxVisibility[index]}
                                            onChange={(e) => handleInputChange(index, 'employee_level', e.target.checked, level.employee_level)}
                                        />
                                        {level.employee_level}
                                    </label>
                                </td>
                                <td>
                                    {checkboxVisibility[index] && (


                                        <TextBox
                                            onValueChanged={(e) => handleInputChange(index, 'amount_eligible', e.value)}
                                            value={formData[index]?.amount_eligible || ''}
                                        />
                                    )}
                                    {errorz[index]?.includes('amount_eligible') && (
                                        <span className="error">This field is required</span>
                                    )}
                                </td>
                                <td>
                                    {checkboxVisibility[index] && (
                                        <TextBox
                                            onValueChanged={(e) => handleInputChange(index, 'sub_expense', e.value)}
                                            value={formData[index]?.sub_expense || ''}
                                        />
                                    )}
                                    {errorz[index]?.includes('sub_expense') && (
                                        <span className="error">This field is required</span>
                                    )}
                                </td>
                                <td>
                                    {checkboxVisibility[index] && (
                                        <DateBox
                                            calendarOptions={{

                                                displayFormat: "yyyy-MM-dd"
                                            }}
                                            onValueChanged={(e) => handleInputChange(index, 'policy_date', e.value)}
                                            value={formData[index]?.policy_date || ''}
                                        />
                                    )}
                                    {errorz[index]?.includes('policy_date') && (
                                        <span className="error">This field is required</span>
                                    )}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                <button className='employBtn' onClick={handleSubmit}>Submit</button>
            </div>




        </>
    )
}