import React, { useCallback, useState } from 'react';
import FileUploader from 'devextreme-react/file-uploader';
import ProgressBar from 'devextreme-react/progress-bar';
import "./excelFileUploader.css"
import { FaUpload } from "react-icons/fa";
import HandleErrorToast from "../../Toaster/Failure/Failure";
import { useDropzone } from "react-dropzone";


export default function ExcelFileUploader({ onFileUploaded }) {
    const onDrop = async (acceptedFiles) => {
        const file = acceptedFiles[0];
        onFileUploaded(file)

    }


    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        noClick: false,
    });

    return (
        <div className="">
            <div className="row">
                <div className="col-9 py-1 ms-3 proofUploadBg mt-3">
                    <div {...getRootProps()} className="TaxProfcurror">
                        <input {...getInputProps()} />
                        <FaUpload className="FaUploadIcon mb-2" />
                        <p className="mb-0">
                            Drag & Drop or
                            <span className="text-primary mx-1">Choose Excel file</span>
                            to upload
                        </p>
                        <p className="uploadTextSmall">Files Supported: Excel</p>
                    </div>
                </div>

            </div>
        </div>
    );
}
