import React from "react";
import { useAuth } from "../../contexts/auth";
import { TextBox } from "devextreme-react";
import { DateBox } from "devextreme-react";
import "./profile.scss";
import { useState, useEffect, useRef } from "react";
import { BASEPATH } from "../../config/config";
import { Button } from "devextreme-react/button";
import { SelectBox } from "devextreme-react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { GET_SINGLE_PROFILE, UPDATE_SINGLE_PROFILE } from "../../api/api";
import { GETONEPROFILE, UPDATEONEPROFILE } from "../../api/requestMethods";
import notify from "devextreme/ui/notify";
const { Country, State, City } = require("country-state-city");

export default function Profilecompany() {
  const { user } = useAuth();

  const [Profilecollection, setProfileCollection] = useState({
    gridData: "",
    gridTrigger: "",
  });
  const [counter, setCounter] = useState(0);
  const [updatechecker, setUpdateChecker] = useState(false);
  const queryClient = useQueryClient();
  const [imageURL, setImageURL] = useState("");
  const [fullimageURL, setFullImageURL] = useState("");
  const fileInputRef = useRef();

  const [checkeruserpanel, setCheckerUserPanel] = useState(false);
  const [counteruserpanel, setCounterUserPanel] = useState(0);
  const [formCollection, setFormCollection] = useState({
    emp_id: "",

    emp_firstname: "",
    emp_lastname: "",
    emp_email: "",
    emp_phone: "",
    emp_level: "",
    emp_role: "",
    emp_dept: "",
    emp_dob: "",
    emp_address: "",
    emp_dateofjoining: "",
    emp_reporting_authority: "",
    emp_country: "",
    emp_city: "",
    emp_state: "",
    emp_zipcode: "",
    emp_image_path: "",
    dummyimg: "",
    imagechecker: "",
  });

  const [updatedformDataFinal, setUpdatedFormDataFinal] = useState(
    new FormData()
  );

  useEffect(() => {
    setFormCollection((prev) => ({
      ...prev,
      imagechecker: true,
    }));
  }, []);

  console.log(user,'user');
  

  useEffect(() => {
    mutationgetprofile.mutate(user.id);
  }, [counter]);

  const fetchSingleProfile = (id) =>
    GETONEPROFILE(GET_SINGLE_PROFILE(id));

  const mutationgetprofile = useMutation({
    mutationKey: ["fetchSingleProfile"],
    mutationFn: (id) => fetchSingleProfile(id),
    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: ["fetchSingleProfile"],
      });
      console.log(data, "mutation PROFILE from backend");

      if (data !== null) {
        const result = data.employee[0];

        setImageURL(data.file);
        setProfileCollection((prev) => ({ ...prev, gridData: result }));
      } else {
        setProfileCollection((prev) => ({ ...prev, gridData: "" }));
      }
    },
    onError: (error) => {
      console.error("Error fetching single profile:", error);
    },
  });

  // initial setting states for all

  useEffect(() => {
    if (Profilecollection.gridData) {
      setFormCollection((prev) => ({
        ...prev,
        emp_id: Profilecollection.gridData.id,
        emp_firstname: Profilecollection.gridData.emp_firstname,
        emp_lastname: Profilecollection.gridData.emp_lastname,
        emp_email: Profilecollection.gridData.emp_email,
        emp_phone: Profilecollection.gridData.emp_phone,
        emp_level: Profilecollection.gridData.emp_level,
        emp_role: Profilecollection.gridData.emp_role,
        emp_dept: Profilecollection.gridData.emp_dept,
        emp_dob: Profilecollection.gridData.emp_dob,
        emp_address: Profilecollection.gridData.emp_address,
        emp_reporting_authority:
          Profilecollection.gridData.emp_reporting_authority,
        emp_country: Profilecollection.gridData.emp_country,
        emp_city: Profilecollection.gridData.emp_city,
        emp_state: Profilecollection.gridData.emp_state,
        emp_zipcode: Profilecollection.gridData.emp_zipcode,
        emp_dateofjoining: Profilecollection.gridData.emp_dateofjoining,
        //emp_image_path: Profilecollection.gridData.emp_image_path,
      }));
    } else {
      console.log("First Name not found");
    }
  }, [Profilecollection.gridData]);

  useEffect(() => {
    setFullImageURL(`${BASEPATH}${imageURL}`);
  }, [imageURL]);

  const handleformCollection = (e) => {
    setFormCollection((prev) => ({ ...prev, emp_firstname: e.value }));
  };
  const handleUpdate = async () => {
    try {
      let formDataForApi = new FormData();
      Object.entries(formCollection).forEach(([key, value]) => {
        if (key === "emp_image_path" && value !== "") {
          formDataForApi.append(key, value, value.name);
        } else if (key === "emp_country" && value !== "") {
          const selectedCountry =
            countryOptions &&
            countryOptions.find((country) => country.value === value);
          if (selectedCountry) {
            formDataForApi.append(key, selectedCountry.label);
          } else {
            formDataForApi.append(key, value);
          }
        } else if (key === "emp_state" && value !== "") {
          const selectedState =
            stateOptions && stateOptions.find((state) => state.value === value);
          if (selectedState) {
            formDataForApi.append(key, selectedState.label);
          } else {
            formDataForApi.append(key, value);
          }
        } else if (key === "emp_city" && value !== "") {
          const selectedCity =
            cityOptions && cityOptions.find((city) => city.value === value);
          if (selectedCity) {
            formDataForApi.append(key, selectedCity.label);
          } else {
            formDataForApi.append(key, value);
          }
        } else {
          formDataForApi.append(key, value);
        }
      });

      setUpdatedFormDataFinal(formDataForApi);
      setUpdateChecker(true);
      console.log(updatedformDataFinal, "updatedFormDataFinal");

      mutationupdateprofile.mutate(user.id);
    } catch (error) {
      console.error("Failed to update:", error);
    }
  };

  //tanstack for handling update profile
  const updateSingleProfile = (id, updatedformDataFinal) =>
    UPDATEONEPROFILE(UPDATE_SINGLE_PROFILE(id), updatedformDataFinal);

  const mutationupdateprofile = useMutation({
    mutationKey: ["updateSingleProfile"],
    mutationFn: (id) => {
      updatechecker && updateSingleProfile(id, updatedformDataFinal);
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: ["fetchSingleProfile"],
      });
      queryClient.invalidateQueries({
        queryKey: ["fetchSingleProfile1"],
      });
      setCounter(counter + 1);
      setCheckerUserPanel(true);
      setCounterUserPanel(counteruserpanel + 1);
    },
    onError: (error) => {
      console.error("Error fetching single profile:", error);
    },
  });

  const handleCancel = () => {
    if (Profilecollection.gridData) {
      setFormCollection((prev) => ({
        ...prev,
        emp_firstname: Profilecollection.gridData.emp_firstname,
        emp_lastname: Profilecollection.gridData.emp_lastname,
        emp_email: Profilecollection.gridData.emp_email,
        emp_phone: Profilecollection.gridData.emp_phone,
        emp_level: Profilecollection.gridData.emp_level,
        emp_role: Profilecollection.gridData.emp_role,
        emp_dept: Profilecollection.gridData.emp_dept,
        emp_dob: Profilecollection.gridData.emp_dob,
        emp_address: Profilecollection.gridData.emp_address,
        emp_reporting_authority:
          Profilecollection.gridData.emp_reporting_authority,
        emp_country: Profilecollection.gridData.emp_country,
        emp_city: Profilecollection.gridData.emp_city,
        emp_state: Profilecollection.gridData.emp_state,
        emp_zipcode: Profilecollection.gridData.emp_zipcode,
        emp_dateofjoining: Profilecollection.gridData.emp_dateofjoining,
        imagechecker: true,
        emp_image_path: Profilecollection.gridData.emp_image_path,
        dummyimg: "",
      }));
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    console.log(file, "new updated image file");
    setFormCollection((prevState) => ({
      ...prevState,
      emp_image_path: file,
      dummyimg: URL.createObjectURL(file),
      imagechecker: false,
    }));
  };

  const handleEditButtonClick = () => {
    fileInputRef.current.click();
  };

  const [countryOptions, setCountryOptions] = useState([]);
  const [stateOptions, setStateOptions] = useState([]);
  const [cityOptions, setCityOptions] = useState([]);

  useEffect(() => {
    const countries = Country.getAllCountries().map((country) => ({
      label: country.name,
      value: country.isoCode,
    }));
    setCountryOptions(countries);
  }, []);

  useEffect(() => {
    if (formCollection.emp_country) {
      const states = State.getStatesOfCountry(formCollection.emp_country).map(
        (state) => ({
          label: state.name,
          value: state.isoCode,
        })
      );
      setStateOptions(states);
    } else {
      setStateOptions([]);
    }
  }, [formCollection.emp_country]);

  useEffect(() => {
    if (formCollection.emp_state) {
      const cities = City.getCitiesOfState(
        formCollection.emp_country,
        formCollection.emp_state
      ).map((city) => ({
        label: city.name,
        value: city.name,
      }));
      setCityOptions(cities);
    } else {
      setCityOptions([]);
    }
  }, [formCollection.emp_state]);

  return (
    <>
           <div className={"dx-card mt-2 p-3 mb-4"}>
              <div className="d-flex justify-content-end gap-3">
                <Button
                  className="profileUpdateBtn"
                  text="Update"
                  onClick={handleUpdate}
                />
                <Button text="Cancel" onClick={handleCancel} />
              </div>

              <hr></hr>
              <div className="row pb-3">
                <div className="col-lg-6">
                  <div className="row">
                    <div className="">
                      <div className="profileImg d-flex align-items-start">
                        <input
                          type="file"
                          accept="image/*"
                          onChange={handleFileChange}
                          style={{ display: "none" }}
                          ref={fileInputRef}
                        />
                        {fullimageURL && formCollection.imagechecker && (
                          <img src={fullimageURL} alt="Employee Avatar" />
                        )}
                        {formCollection.dummyimg && (
                          <img src={formCollection.dummyimg} alt="Employee" />
                        )}
                                                <Button icon="edit" className="edit" onClick={handleEditButtonClick} />

                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6 pb-3">
                  <TextBox
                    label="First Name"
                    value={formCollection.emp_firstname}
                    onValueChanged={handleformCollection}
                  />
                </div>

                <div className="col-lg-6 pb-3">
                  <TextBox
                    label="Last Name"
                    value={formCollection.emp_lastname}
                    onValueChanged={(e) =>
                      setFormCollection((prev) => ({
                        ...prev,
                        emp_lastname: e.value,
                      }))
                    }
                  />
                </div>

                <div className="col-lg-3">
                  <TextBox
                    label="Department"
                    value={formCollection.emp_dept}
                    onValueChanged={(e) =>
                      setFormCollection((prev) => ({
                        ...prev,
                        emp_dept: e.value,
                      }))
                    }
                  />
                </div>
                <div className="col-lg-3">
                  <TextBox
                    label="Level"
                    value={formCollection.emp_level}
                    onValueChanged={(e) =>
                      setFormCollection((prev) => ({
                        ...prev,
                        emp_level: e.value,
                      }))
                    }
                  />
                </div>

                <div className="col-lg-3">
                  <DateBox
                    label="Date of Joining"
                    type="date"
                    value={formCollection.emp_dateofjoining}
                    onValueChanged={(e) =>
                      setFormCollection((prev) => ({
                        ...prev,
                        emp_dateofjoining: e.value,
                      }))
                    }
                  />
                </div>
                <div className="col-lg-3">
                  <DateBox
                    label="Date of Birth"
                    type="date"
                    value={formCollection.emp_dob}
                    onValueChanged={(e) =>
                      setFormCollection((prev) => ({
                        ...prev,
                        emp_dob: e.value,
                      }))
                    }
                  />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-6">
                <div className={"dx-card responsive-paddings p-3"}>
                  <h5>Contacts</h5>
                  <hr></hr>
                  <div className={""}>
                    <div className="row">
                      <div className="col-lg-6 pb-3">
                        <TextBox
                          label="Phone"
                          value={formCollection.emp_phone}
                          onValueChanged={(e) =>
                            setFormCollection((prev) => ({
                              ...prev,
                              emp_phone: e.value,
                            }))
                          }
                        />
                      </div>
                      <div className="col-lg-6 pb-3">
                        <TextBox
                          label="Email"
                          value={formCollection.emp_email}
                          onValueChanged={(e) =>
                            setFormCollection((prev) => ({
                              ...prev,
                              emp_email: e.value,
                            }))
                          }
                        />
                      </div>
                      <div className="col-lg-12 pb-3">
                        <TextBox
                          label="Reporting Authority"
                          value={formCollection.emp_reporting_authority}
                          onValueChanged={(e) =>
                            setFormCollection((prev) => ({
                              ...prev,
                              emp_reporting_authority: e.value,
                            }))
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className={"dx-card responsive-paddings p-3"}>
                  <h5>Address</h5>
                  <hr></hr>
                  <div className={""}>
                    <div className="row">
                      <div className="col-lg-6 pb-3">
                        <SelectBox
                          dataSource={countryOptions}
                          displayExpr="label"
                          valueExpr="value"
                          value={formCollection.emp_country}
                          onValueChanged={(e) =>
                            setFormCollection((prev) => ({
                              ...prev,
                              emp_country: e.value,
                            }))
                          }
                          placeholder={formCollection.emp_country}
                        />
                      </div>
                      <div className="col-lg-6 pb-3">
                        <SelectBox
                          dataSource={stateOptions}
                          displayExpr="label"
                          valueExpr="value"
                          value={formCollection.emp_state}
                          onValueChanged={(e) =>
                            setFormCollection((prev) => ({
                              ...prev,
                              emp_state: e.value,
                            }))
                          }
                          placeholder={formCollection.emp_state}
                        />
                      </div>
                      <div className="col-lg-12 pb-3">
                        <SelectBox
                          dataSource={cityOptions}
                          displayExpr="label"
                          valueExpr="value"
                          value={formCollection.emp_city}
                          onValueChanged={(e) =>
                            setFormCollection((prev) => ({
                              ...prev,
                              emp_city: e.value,
                            }))
                          }
                          placeholder={formCollection.emp_city}
                        />
                      </div>
                      <div className="col-lg-12 pb-3">
                        <TextBox
                          label="Address"
                          value={formCollection.emp_address}
                          onValueChanged={(e) =>
                            setFormCollection((prev) => ({
                              ...prev,
                              emp_address: e.value,
                            }))
                          }
                        />
                      </div>
                      <div className="col-lg-12 pb-3">
                        <TextBox
                          label="Zip Code"
                          value={formCollection.emp_zipcode}
                          onValueChanged={(e) =>
                            setFormCollection((prev) => ({
                              ...prev,
                              emp_zipcode: e.value,
                            }))
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
    </>
  );
}
