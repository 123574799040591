import { getAPIKey } from "./api/getApiKey";
const apiKey = getAPIKey();
const apiKeyObject = JSON.parse(apiKey);

export const navigation = [
  {
    text: "Dashboard",
    icon: "chart",
    path: "/dashboard",
  },
  {
    text: "Profile",
    icon: "accountbox",
    path: "/Profile",
  },

  {
    text: "Employees",
    icon: "card",
    path: "/Employees",
  },
  {
    text: "Expenses",
    icon: "sorted",
    path: "/Expenses",
  },
  {
    text: "Cost Center",
    icon: "pinmap",
    path: "/CostCenter",
  },

  {
    text: "Policy",
    icon: "taskhelpneeded",
    items: [
      {
        text: "Apply",
        path: "/Policy/Apply",
      },
      {
        text: "History",
        path: "/Policy/History",
      },
    ],
  },
];
