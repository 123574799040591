import "./polyfills";
import React from "react";
import { createRoot } from "react-dom/client";
import "./index.css";
import App from "./App";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";


const queryClient = new QueryClient({
  // defaultOptions: {queries: {staleTime: 1000 * 60 * 5}},
});
const rootElement = document.getElementById("root");
const root = createRoot(rootElement);
root.render(
  <QueryClientProvider client={queryClient}>
    <App />
    {/* <ReactQueryDevtools initialIsOpen={false} /> */}
  </QueryClientProvider>
);
