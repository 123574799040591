import axios from "axios";
import { getAPIKey } from "./getApiKey";
import getHostAPIUrl from "./getHostApiUrl";
import { BASEPATH } from "../config/config";

const appAxios = axios.create({
  baseURL: BASEPATH,
  timeout: 60000,
  responseType: "json",
});

appAxios.interceptors.request.use(
  async (config) => {
    const apiKey = getAPIKey();
    const apiKeyObject = JSON.parse(apiKey);
    if (apiKeyObject) {
      config.headers["Database-Name"] = apiKeyObject?.databasename;
    }
    return config;
  },
  (error) => {
    //return Promise.reject(error);
  }
);

export default appAxios;
