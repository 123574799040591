import { HomePage, TasksPage, ProfilePage } from "./pages";
import { withNavigationWatcher } from "./contexts/navigation";
import Dashboard from "./pages/dashboard/Dashboard";
import profile from "./pages/profile/profile.tsx";
import Profilecompany from "./pages/profile/Profilecompany.js";
import Employees from "./pages/Employees/Employees/Employees";
import { getAPIKey } from "./api/getApiKey.js";
import Apply from "./pages/Policies/Apply_Policy/Apply.js";
import History from "./pages/Policies/History_Policy/History.js";
import CostCenter from "./pages/Cost Center/CostCenter.js";
import Expenses from "./pages/Expenses/Expenses.js";

const apiKey = getAPIKey();

const apiKeyObject = JSON.parse(apiKey);

let isAdmin = apiKeyObject?.isAdmin;
let isEmployee = apiKeyObject?.isEmployee;

const routes = [
  {
    path: "/dashboard",
    element: Dashboard,
  },
  // {
  //     path: '/Profile',
  //     element: profile
  // },
  {
    path: "/Profile",
    element: Profilecompany,
  },
  {
    path: "/Employees",
    element: Employees,
  },
  {
    path: "/Expenses",
    element: Expenses,
  },
  {
    path: "/CostCenter",
    element: CostCenter,
  },

  {
    path: "/Policy/Apply", // Adjusted path for Apply
    element: Apply, // Component for Apply
  },
  {
    path: "/Policy/History", // Adjusted path for History
    element: History, // Component for History
  },
];

//const filteredRoutes = isAdmin === "true" ? routes : routes.filter(route => route.path !== '/Employees');

export default routes.map((route) => {
  return {
    ...route,
    element: withNavigationWatcher(route.element, route.path),
  };
});

// or u can filter the routes array. javascript filter() method
