import React from 'react';
import './home.scss';
import { Button } from 'devextreme-react/button';
import { Card } from 'react-bootstrap';
import { useAuth } from '../../contexts/auth';
import { useState, useEffect } from 'react';
import { SelectBox } from 'devextreme-react';
import { YEARS_COLLECTION, MONTHS_COLLECTION, dataSource, populationByRegions, grossProductData } from './data';

import {
  Chart,
  //Series,
  ArgumentAxis,
  CommonSeriesSettings,
  Margin,
  //Export,
  //Legend,
} from 'devextreme-react/chart';

import PieChart, {
  Legend,
  Series,
  Tooltip,
  Format,
  Label,
  Connector,
  Export,
} from 'devextreme-react/pie-chart';

import {
  CircularGauge,
  Scale,
  //Label,
  RangeContainer,
  Range,
  Title,
  Font,
  //Export,
} from 'devextreme-react/circular-gauge';



export default function Dashboard() {

  const [checker, setChecker] = useState("initial")
  const currentYear = new Date().getFullYear();
  const [finalCollection, setFinalCollection] = useState({
    claim_amount: "",
    reimbursed_amount: "",
    balance_amount: "",
    selectedYear: "",
    selectedMonth: "",

  })
  const[allyearsshow, setAllYearsShow] = useState(false)


  // initial state
  useEffect(() => {

    const sortedYears = YEARS_COLLECTION && YEARS_COLLECTION.sort((a, b) => parseInt(b.name) - parseInt(a.name));

    const highestYear = sortedYears[0];


    const selectedMonthObject = MONTHS_COLLECTION && MONTHS_COLLECTION.find(month => month.name === 'January');

    if (checker === "initial") {
      setFinalCollection(prev => ({
        ...prev,
        selectedYear: highestYear.name,
        selectedMonth: selectedMonthObject.name,
        claim_amount: 100,
        reimbursed_amount: 100,
        balance_amount: 0,

      }));
    }

  }, [checker])

  //general condition

  useEffect(() => {
    if (finalCollection.selectedYear === "2023") {
      setFinalCollection(prev => ({
        ...prev,
        claim_amount: 156788,
        reimbursed_amount: 100000,
        balance_amount: 567888,


      }));
    }
    else if (finalCollection.selectedYear === "2022") {
      setFinalCollection(prev => ({
        ...prev,
        claim_amount: 5643,
        reimbursed_amount: 1123,
        balance_amount: 764,



      }));
    }
    else if (finalCollection.selectedYear === "2021") {
      setFinalCollection(prev => ({
        ...prev,
        claim_amount: 9991,
        reimbursed_amount: 3336,
        balance_amount: 5656,



      }));
    }
    else if (finalCollection.selectedYear === "2020") {
      setFinalCollection(prev => ({
        ...prev,
        claim_amount: 1122,
        reimbursed_amount: 66666,
        balance_amount: 9878,



      }));
    }
    else if (finalCollection.selectedYear === "2019") {
      setFinalCollection(prev => ({
        ...prev,
        claim_amount: 999,
        reimbursed_amount: 4545,
        balance_amount: 343,



      }));
    }



  }, [finalCollection.selectedYear])

  const handleAllYearsButton = () => {
    setAllYearsShow(true)
    setFinalCollection(prev => ({
      ...prev,
      claim_amount: 99999999,
      reimbursed_amount: 8888888,
      balance_amount: 1111111,
      //selectedYear: "Total"



    }));

   

  }




  const handleYearChange = (value) => {
    //setChecker("change")
    setFinalCollection(prev => ({
      ...prev,
      selectedYear: value
    }));
    setAllYearsShow(false)

  }

  const handleMonthChange = (value) => {
    //setChecker("change")
    setFinalCollection(prev => ({
      ...prev,
      selectedMonth: value
    }));
    setAllYearsShow(false)
  }

  function customizeTooltip(arg) {
    return {
      text: `${arg.valueText} - ${(arg.percent * 100).toFixed(2)}%`,
    };
  }

  function onPointClick(e) {
    e.target.select();
  }


  return (
    <React.Fragment>
        <div className={'dx-card mt-2 responsive-paddings'}>
          <div className='d-flex justify-content-start gap-3'>
            <div className="d-flex">
              <SelectBox
                label="Select Year:"
                labelMode="floating"
                dataSource={YEARS_COLLECTION}
                displayExpr="name"
                valueExpr="name"
                onValueChanged={(e) => handleYearChange(e.value)}
                value={finalCollection.selectedYear}
              >

              </SelectBox>
              <div className='ms-3'>
                <SelectBox
                  label="Select Month:"
                  labelMode="floating"
                  dataSource={MONTHS_COLLECTION}
                  displayExpr="name"
                  valueExpr="name"
                  onValueChanged={(e) => handleMonthChange(e.value)}
                  value={finalCollection.selectedMonth}
                >

                </SelectBox>
              </div>
            </div>
            <Button
              className='profileUpdateBtn'
              text="All Years" onClick={handleAllYearsButton} />

          </div>



          {checker &&
            <>
              <div className="row mt-3">
                <div className="col-lg-4 ">
                  <div>
                    <Card >
                      <Card.Img variant="top" />
                      <Card.Body>
                        <Card.Title></Card.Title>
                        {/* Render other employee details */}
                        <Card.Text className="mb-0">
                          <div className="d-flex align-items-center">
                            <div className="icon-wrapper positive"><li className="dx-icon dx-icon-datatrending"></li></div>
                            <div className="ms-3">
                              {finalCollection.selectedYear} Claim Amount:
                              <div className="fs-5">
                                <b>{finalCollection.claim_amount}</b>
                              </div>
                            </div>
                          </div>
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div>
                    <Card>
                      <Card.Img variant="top" />
                      <Card.Body>
                        <Card.Title>{ }</Card.Title>
                        <Card.Text className="mb-0">
                          <div className="d-flex align-items-center">
                            <div className="icon-wrapper negative"><li className="dx-icon dx-icon-dataarea"></li></div>
                            <div className="ms-3">
                              {finalCollection.selectedYear} Reimbursed Amount:
                              <div className="fs-5">
                                <b> {finalCollection.reimbursed_amount}</b>
                              </div>
                            </div>
                          </div>
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div>
                    <Card>
                      <Card.Img variant="top" />
                      <Card.Body>
                        <Card.Title>{ }</Card.Title>
                        <Card.Text className="mb-0">
                          <div className="d-flex align-items-center">
                            <div className="icon-wrapper warning"><li className="dx-icon dx-icon-datausage"></li></div>
                            <div className="ms-3">
                              {finalCollection.selectedYear} Balance Amount:
                              <div className="fs-5">
                                <b> {finalCollection.balance_amount}</b>
                              </div>
                            </div>
                          </div>
                        </Card.Text>
                      </Card.Body>
                    </Card> </div>
                </div>
              </div>
            </>
          }

          <div className="row pt-3">
            <div className="col-lg-6">
              <div className="shadow p-3">
                <Chart
                  palette="Harmony Light"
                  title={`Claimed vs Approved for the year ${finalCollection.selectedYear}`}
                  dataSource={dataSource}
                >
                  <CommonSeriesSettings
                    argumentField="company"
                    type="splinearea"

                  />
                  <Series
                    valueField="yClaimed"
                    name="Claimed"
                  ></Series>
                  <Series
                    valueField="yApproved"
                    name="Approved"
                  ></Series>
                  <ArgumentAxis valueMarginsEnabled={false} />
                  <Legend
                    verticalAlignment="bottom"
                    horizontalAlignment="center"
                  />
                  <Margin bottom={20} />
                  <Export enabled={true} />

                  <Tooltip
                    enabled={true}
                    customizeTooltip={({ argumentText, seriesName, valueText }) => {

                      const dataItem = dataSource.find(item => item.company === argumentText);


                      if (dataItem && seriesName) {

                        if (seriesName === "Claimed" || seriesName === "Approved") {

                          return {
                            text: `
                <div>
                  <div>${argumentText}</div>
                  <div>Claimed: ${dataItem.yClaimed}</div>
                  <div>Approved: ${dataItem.yApproved}</div>
                </div>
              `
                          };
                        }
                      }


                      return {
                        text: valueText
                      };
                    }}
                  />
                </Chart>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="shadow p-3">
                <PieChart
                  id="pie"
                  type="doughnut"
                  //title="Expense Heads and Claimed Amount"
                  title={`Expense Heads vs Claimed Amount for the year ${finalCollection.selectedYear}`}
                  palette="Soft Pastel"
                  dataSource={populationByRegions}
                >
                  <Series argumentField="region">
                    <Label
                      visible={true}
                    //format="millions"
                    >
                      <Connector visible={true} />
                    </Label>
                  </Series>
                  <Export enabled={true} />
                  <Legend
                    margin={0}
                    horizontalAlignment="right"
                    verticalAlignment="top"
                  />
                  <Tooltip
                    enabled={true}
                    customizeTooltip={customizeTooltip}
                  >
                    <Format type="millions" />
                  </Tooltip>
                </PieChart>
              </div>
            </div>
            {/* <div className="col-lg-4">


            </div> */}
          </div>

          <div className="row py-4">
            <div className="col-lg-12">
              {/* Vignesh */}
              <CircularGauge
      id="gauge"
      value={105}
    >
      <Scale
        startValue={50}
        endValue={150}
        tickInterval={10}
      >
        <Label useRangeColors={true} />
      </Scale>
      <RangeContainer palette="Pastel">
        <Range
          startValue={50}
          endValue={90}
        />
        <Range
          startValue={90}
          endValue={130}
        />
        <Range
          startValue={130}
          endValue={150}
        />
      </RangeContainer>
      <Title text={`Expenses Detail for Year ${finalCollection.selectedYear} and Month ${finalCollection.selectedMonth}`}>
        <Font size={28} />
      </Title>
      <Export enabled={true} />
    </CircularGauge>
            </div>
          </div>
       { allyearsshow &&
          <div className="col-lg-12 py-4">
            <div className="shadow p-3">
              <Chart
                id="chart"
                title="Total Expenses Report for all years"
                dataSource={grossProductData}
                onPointClick={onPointClick}
              >
                <CommonSeriesSettings
                  argumentField="state"
                  type="bar"
                  hoverMode="allArgumentPoints"
                  selectionMode="allArgumentPoints"
                >
                  <Label visible={true}>
                    <Format
                      type="fixedPoint"
                      precision={0}
                    />
                  </Label>
                </CommonSeriesSettings>
                <Series
                  argumentField="state"
                  valueField="year2024"
                  name="2024"
                />
                <Series
                  valueField="year2023"
                  name="2023"
                />
                <Series
                  valueField="year2022"
                  name="2022"
                />
                <Series
                  valueField="year2021"
                  name="2021"
                />
                <Series
                  valueField="year2020"
                  name="2020"
                />
                <Series
                  valueField="year2019"
                  name="2019"
                />
                <Legend
                  verticalAlignment="bottom"
                  horizontalAlignment="center"
                ></Legend>
                <Export enabled={true} />
              </Chart>
            </div>
          </div> }

        </div>




    </React.Fragment>
  )
}
